<template>
    <div v-if="visible">

        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            persistent 
            max-width="700"
        >            
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{$t("Смена_пароля")}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text v-if="loading">
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{$t("Загрузка...")}}
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>
                </v-card-text>

                <v-card-text v-else class="wrapperFormModal">
                    
                        <v-card flat>
                            <v-card-text>
                                <div v-if="showmessage" class="font-weight-bold">
                                    {{ $t("Срок_действия_вашего_пароля_истёк,_введите_новый_пароль") }}:
                                </div>
                                <v-row no-gutters v-if="askOldPass">
                                    <v-col cols="12" sm="12" md="3">
                                        <label class="f-label">{{$t("Пароль")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="9">
                                        <v-text-field 
                                            :append-icon="showPasswordOld ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="showPasswordOld ? 'text' : 'password'"
                                            v-model="password_old"
                                            name="input-10-1"
                                            hide-details
                                            @click:append="showPasswordOld = !showPasswordOld"
                                            prepend-inner-icon="mdi-lock" 
                                            :placeholder="$t('Пароль')"
                                            autocomplete="new-password"
                                            required 
                                            outlined 
                                            dense
                                        >
                                        </v-text-field>   
                                    </v-col>
                                </v-row>

                                <v-form ref="form">

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="3">
                                            <label class="f-label">{{$t("Новый_пароль")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="9">
                                            <v-text-field 
                                                :append-icon="showPasswordNew ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required, rules.min]"
                                                :type="showPasswordNew ? 'text' : 'password'"
                                                v-model="password_new"
                                                name="input-10-1"
                                                hide-details
                                                @click:append="showPasswordNew = !showPasswordNew"
                                                prepend-inner-icon="mdi-lock" 
                                                :placeholder="$t('Новый_пароль')"
                                                autocomplete="new-password"
                                                required 
                                                outlined 
                                                dense
                                            >
                                            </v-text-field> 
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="3">
                                            <label class="f-label">{{$t("Подтверждение_нового_пароля")}}</label>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="9">
                                            <v-text-field 
                                                :append-icon="showPasswordRepeatNew ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.required, rules.min, confirmPassword]"
                                                :type="showPasswordRepeatNew ? 'text' : 'password'"
                                                v-model="password_repeat_new"
                                                name="input-10-1"
                                                hide-details
                                                @click:append="showPasswordRepeatNew = !showPasswordRepeatNew"
                                                prepend-inner-icon="mdi-lock" 
                                                :placeholder="$t('Подтверждение_нового_пароля')"
                                                autocomplete="new-password"
                                                required 
                                                outlined 
                                                dense
                                            >
                                            </v-text-field> 
                                        </v-col>
                                    </v-row>
                                    
                                </v-form>
                            </v-card-text>
                        </v-card>

                        <v-card color="#f1b54c" v-if="helptextarray.length > 0" flat>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="12">
                                        <div class="font-weight-bold">{{ $t("Требования_к_паролю") }}:</div>
                                        <div 
                                            v-for="(item,index) in helptextarray"
                                            :key="index"
                                        >

                                            <span v-html="item.text">
                                            </span>

                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        v-show="password_old && password_new && password_repeat_new && password_new === password_repeat_new"
                        color="cyan" 
                        text
                        depressed
                        @click="ok"
                    >
                        {{$t("Сохранить")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed
                        @click="cancel"
                    >
                        {{$t("Закрыть")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import { httpAPI } from '@/api/httpAPI';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import i18n from '@/i18n';

export default {
    name: "UserReplacePassword",
    data() {
        return {
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            resolve: null,
            reject: null,
            rules: {
                required: function (value) {
                    return (!!value) || i18n.t('Не_может_быть_пустым.');
                },
                min: function (value) {
                    return (value && value.length >= 3) || i18n.t('Не_менее_3_символов');
                },
            },
            showPasswordOld: false,
            showPasswordNew: false,
            showPasswordRepeatNew: false,
        };
    },
    computed: {
        ...mapGetters('dialogs/userReplacePassword',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            showmessage: 'getShowMessage',
            getPassword_old: 'getOldPassword',
            getPassword_new: 'getNewPassword',
            getPassword_repeat_new: 'getNewRepeatPassword',
            helptextarray: 'getHelpTextArray',
            askOldPass: 'getAskOldPass'
        }),
        password_old: {
            get: function() {
                return this.getPassword_old;
            },
            set: _.debounce(function(value) {
                this.$store.commit('dialogs/userReplacePassword/SET_PASSWORD_OLD', value );
            }, 500)
        },
        password_new: {
            get: function() {
                return this.getPassword_new;
            },
            set: _.debounce(function(value) {
                this.$store.commit('dialogs/userReplacePassword/SET_PASSWORD_NEW', value );
            }, 500)
        },
        password_repeat_new: {
            get: function() {
                return this.getPassword_repeat_new;
            },
            set: _.debounce(function(value) {
                this.$store.commit('dialogs/userReplacePassword/SET_PASSWORD_REPEAT_NEW', value );
            }, 500)
        },
    },
    methods: {
        ...mapActions('dialogs/userReplacePassword', [ 'ok', 'cancel' ]),
        confirmPassword(value) {
            return (value === this.password_new) || 'Новый_пароль_не_подтвержден';
        },
    },
};
</script>
