import { httpAPI } from "@/api/httpAPI";
const dataSourceTemplate = {
    Executers:
    [ 
        { id: 1, type: 'InnerExecuters', name: 'Текущая_организация', children: [] },
        { id: 2, type: 'OuterExecuters', name: 'Внешние_исполнители', children: [] }
    ],
    Correspondents:
    [ 
        { id: 3, type: 'CorrespondentsESEDO', name: 'Участники_EСЭДО', children: [] },
        { id: 4, type: 'CorrespondentsJuridical', name: 'Юридические_лица', children: [] },
        { id: 5, type: 'CorrespondentsPersons', name: 'Физические_лица', children: [] }
    ],
    ExecuterGroups: []
};

const listToTree = function (list) {

    if (!list?.length)
        return [{ children: [], parent_id: null, name: "Нет_доступных_элементов", element_category: "ErrorMessage", element_type: "Empty" }];

    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];

        if (node.parent_id) {
            list[map[node.parent_id]].children.push(node);
        }
        else {
            roots.push(node);
        }
    }

    return roots;
}

const selectMembersNew = {
    namespaced: true,
    state: {
        /**Признак видимости модального окна */
        visible: false,
        /**Признак, что в данный момент происходит загрузка данных */
        loadingExecuters: true,
        /**Признак, что в данный момент происходит загрузка данных */
        loadingCorrespondents: true,
        /**Признак, что в данный момент происходит загрузка данных */
        loadingExecuterGroups: true,
        /**Параметры, с которыми было вызвано модальное окно */
        options: null,
        /**Индекс текушей вкладки */
        tab: 0,
        /**Вкладки модального окна */
        tabs: [
            { type: 'Executers', name: "Исполнители", icon: "fas fa-user" },
            { type: 'Correspondents', name: "Внешние_корреспонденты", icon: "fas fa-landmark" },
            { type: 'ExecuterGroups', name: "Группы_исполнителей", icon: "fas fa-users" }
        ],
        //** Узлы для дерева */
        dataSource: null,

        //**Хранилище для выбранных объектов - { type, enterprise, workplaceId, employeeId, name, description, esedoMember } */
        selectedItems: [],
        //**Хранилище для элементов, которые нельзя выбрать */
        excludedItemsIds: [],
        /**Признак, что данные всегда нужно получать с сервера */
        ignoreCache: false,
        /**Строка поиска (общая) */
        search: null,

        resolve: null,
        reject: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING_EXECUTERS(state, payload) {
            state.loadingExecuters = payload;
        },
        SET_LOADING_CORRESPONDENTS(state, payload) {
            state.loadingCorrespondents = payload;
        },
        SET_LOADING_EXECUTER_GROUPS(state, payload) {
            state.loadingExecuterGroups = payload;
        },
        SET_OPTIONS(state, payload) {
            state.options = payload;
        },
        SET_TAB(state, payload) {
            state.tab = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        RESET_DATA_SOURCE(state, payload) {
            state.dataSource = payload;
        },
        PUSH_CHILDREN_TO_NODE(state, payload) {
            if (payload.subType) {
                let arr = state.dataSource[payload.type];
                let index = arr.findIndex(x => x.type === payload.subType)
                arr[index].children = payload.makeTree ? listToTree(payload.items) : payload.items;
            }
            else {
                state.dataSource[payload.type] = payload.makeTree ? listToTree(payload.items) : payload.items;
            }
        },
        SET_SELECTED_ITEMS(state, payload) {
            if (payload.length > 0 && state.options.multiple !== true)
                state.selectedItems = [payload[payload.length - 1]];
            else
                state.selectedItems = payload;
        },
        SET_EXCLUDED_ITEMS_IDS(state, payload) {
            state.excludedItemsIds = payload;
        },
        SET_IGNORE_CACHE(state, payload) {
            state.ignoreCache = payload;
        },
    },
    actions: {
        async open({ commit, getters, dispatch }, options) {
            let curorg = dataSourceTemplate.Executers.find(item => item.id==1);
            if (curorg)
                curorg.name = options.NPASignersAct && options.EnterpriseAdjastmentName ?  options.EnterpriseAdjastmentName : 'Текущая_организация';
            
            commit('RESET_DATA_SOURCE', JSON.parse(JSON.stringify(dataSourceTemplate)));
            commit('SET_EXCLUDED_ITEMS_IDS', options.implicitExclude ?? []);
            commit('SET_OPTIONS', options);
            commit('SET_VISIBLE', true);
            
            //загружаем данные по переданным идентификаторам выбранных
            if (options.selected && options.selected.length > 0) {
                let responce = await httpAPI({
                    url: `api/ems/selectedexecuterslist?filter=${JSON.stringify({selectedids: options.selected})}`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (responce) 
                    commit('SET_SELECTED_ITEMS', responce.data.payload);
                else 
                    commit('SET_SELECTED_ITEMS', []);
            }
            else commit('SET_SELECTED_ITEMS', []);
            
            if (!getters.getCurrentTab) {
                commit('SET_VISIBLE', false);
                return Promise.reject({ message: "Нет доступных типов для выбора!" });
            }

            await dispatch('updateDataSource', getters.getCurrentTab.type);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ dispatch, state }) {
            let nonReactiveSelected = JSON.parse(JSON.stringify(state.selectedItems));

            if (state.options.multiple === true)
                state.resolve(nonReactiveSelected);
            else
                state.resolve(nonReactiveSelected[0]);

            dispatch('reset');
        },
        async cancel({ dispatch, state }) {            
            state.reject({ message: "Cancelled" });
            dispatch('reset');
        },
        async handleTabChange({ dispatch, getters }) {
            await dispatch('updateDataSource', getters.getCurrentTab.type);
        },
        async updateDataSource({ state, commit, dispatch }, currentTabType) {
            switch (currentTabType) {
                case "Executers": {
                    
                    if (state.ignoreCache)
                        commit('SET_LOADING_EXECUTERS', true);

                    let refItems = {};
                    if (state.options.NPASignersAct){
                        refItems = await dispatch('references/getDialogExecutersForNPA',
                            { 
                                params: 'SignersAct' 
                            }, { root: true });
                    }
                    else if (state.options.NPAAdjasments){
                        refItems = await dispatch('references/getDialogExecutersForNPA',
                            { 
                                params: 'Adjasments'
                            }, { root: true });
                    }
                    else {
                        refItems = await dispatch('references/getNewDialogExecuters',
                        { 
                            params: {
                                include_inner: state.options.includeInner,
                                include_outer: state.options.includeOuter,
                                applyresolutions: state.options.applyresolutions,
                                signersonly: state.options.onlySigners,
                                ignore_cache: state.ignoreCache,
                                signers_boundaries: state.options.onlySigners === true && state.options.includeOuter === true
                                    ? Object.prototype.hasOwnProperty.call(state.options, "signersBoundaries") && Array.isArray(state.options.signersBoundaries)
                                        ? state.options.signersBoundaries.join()
                                        : null
                                    : null
                            }
                        }, { root: true });
                    }
                    if (state.options.includeInner && 
                            (state.ignoreCache || 
                                (state.dataSource.Executers.find(x => x.type == 'InnerExecuters')?.children.length === 0 || 
                                    (state.options.onlySigners === true ? !refItems.Signers.cached : (state.options.applyresolutions === true ? !refItems.ApplyResolutions.cached : !refItems.InnerExecuters.cached))
                                )
                            )
                        )
                        
                        commit('PUSH_CHILDREN_TO_NODE',
                                { 
                                    type: "Executers",
                                    subType: "InnerExecuters",
                                    items: state.options.onlySigners === true ? refItems.Signers.values : (state.options.applyresolutions === true? refItems.ApplyResolutions.values : refItems.InnerExecuters.values),
                                    makeTree: true
                                });

                    if (state.options.includeOuter && (state.ignoreCache || (state.dataSource.Executers.find(x => x.type == 'OuterExecuters')?.children.length === 0 || (state.options.onlySigners === true ? !refItems.OuterSigners.cached : !refItems.OuterExecuters.cached))))
                        commit('PUSH_CHILDREN_TO_NODE',
                                { 
                                    type: "Executers",
                                    subType: "OuterExecuters",
                                    items: state.options.onlySigners === true ? refItems.OuterSigners.values : refItems.OuterExecuters.values,
                                    makeTree: true
                                });
                    
                    commit('SET_LOADING_EXECUTERS', false);
                    break;
                }
                case "Correspondents": {

                    if (state.ignoreCache)
                        commit('SET_LOADING_CORRESPONDENTS', true);

                    let refItems = await dispatch('references/getNewDialogExecuters',
                        { 
                            params: {
                                include_esedo: state.options.includeOuterEnterprises,
                                include_jur: state.options.includeJuridical,
                                include_fiz: state.options.includePersons,
                                include_work_enterprises: !state.options.includeOuter,
                                ignore_cache: state.ignoreCache
                            }
                        }, { root: true })

                    if (state.options.includeOuterEnterprises && (state.ignoreCache || (state.dataSource.Correspondents.find(x => x.type == 'CorrespondentsESEDO')?.children.length === 0 || !refItems.OuterESEDO.cached)))
                        commit('PUSH_CHILDREN_TO_NODE',
                                {
                                    type: "Correspondents",
                                    subType: "CorrespondentsESEDO",
                                    items: refItems.OuterESEDO.values,
                                    makeTree: false
                                });
                    
                    if (state.options.includeJuridical && (state.ignoreCache || (state.dataSource.Correspondents.find(x => x.type == 'CorrespondentsJuridical')?.children.length === 0 || !refItems.OuterJur.cached)))
                        commit('PUSH_CHILDREN_TO_NODE',
                                {
                                    type: "Correspondents",
                                    subType: "CorrespondentsJuridical",
                                    items: refItems.OuterJur.values,
                                    makeTree: false
                                });
                    
                    if (state.options.includePersons && (state.ignoreCache || (state.dataSource.Correspondents.find(x => x.type == 'CorrespondentsPersons')?.children.length === 0 || !refItems.OuterFiz.cached)))
                        commit('PUSH_CHILDREN_TO_NODE',
                                {
                                    type: "Correspondents", 
                                    subType: "CorrespondentsPersons",
                                    items: refItems.OuterFiz.values,
                                    makeTree: false
                                });

                    commit('SET_LOADING_CORRESPONDENTS', false);                                
                    break;
                }
                case "ExecuterGroups": {
                    
                    if (state.ignoreCache)
                        commit('SET_LOADING_EXECUTER_GROUPS', true);

                    let refItems = await dispatch('references/getNewDialogExecuters',
                        { 
                            params: {
                                include_groups: state.options.includeExecutersGroups,
                                ignore_cache: state.ignoreCache
                            }
                        }, { root: true })
                    
                    if (state.options.includeExecutersGroups && (state.ignoreCache || (state.dataSource.ExecuterGroups.length === 0 || !refItems.ExecuterGroups.cached))) {
                        let items = refItems.ExecuterGroups.values;
                        
                        items.forEach(x => {
                            if (x.element_category === 'InnerExecuter')
                                x.allow = x.allow && state.options.includeInner;

                            if (x.element_category === 'OuterExecuter')
                                x.allow = x.allow && state.options.includeOuter;

                            if (x.element_category === 'OuterESEDO')
                                x.allow = x.allow && state.options.includeOuterEnterprises;
                        });

                        //console.log(items);

                        commit('PUSH_CHILDREN_TO_NODE',
                                { 
                                    type: "ExecuterGroups",
                                    items,
                                    makeTree: true
                                });
                    }

                    commit('SET_LOADING_EXECUTER_GROUPS', false);
                    break;
                }

                default:
                    break;
            }
        },
        reset({ commit }) {
            commit('SET_VISIBLE', false);
            commit('SET_LOADING_EXECUTERS', true);
            commit('SET_LOADING_CORRESPONDENTS', true);
            commit('SET_LOADING_EXECUTER_GROUPS', true);
            commit('SET_TAB', 0);
            commit('SET_SEARCH', null);
            commit('SET_SELECTED_ITEMS', []);
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoadingExecuters: (s) => s.loadingExecuters,
        isLoadingCorrespondents: (s) => s.loadingCorrespondents,
        isLoadingExecuterGroups: (s) => s.loadingExecuterGroups,
        getSearch: (s) => s.search,
        getIgnoreCache: (s) => s.ignoreCache,
        getOptions: (s) => s.options,
        getTab: (s) => s.tab,
        getTabs (state) {
            let tabs = [];
            
            if (!state.options)
                return tabs;

            if (state.options.includeInner || state.options.includeOuter) {
                tabs.push(state.tabs[0]);
            }

            if (state.options.includeOuterEnterprises || state.options.includeJuridical || state.options.includePersons) {
                tabs.push(state.tabs[1]);
            }

            if (state.options.includeExecutersGroups) {
                tabs.push(state.tabs[2]);
            }

            return tabs;
        },
        getCurrentTab (state, getters) {
            if (getters.getTabs.length)
                return getters.getTabs[state.tab];

            return null;
        },
        getExecuterTabTreeNodes (state) {
            let root = [];
            
            if (state.options.includeInner)
                root.push(state.dataSource.Executers[0]);

            if (state.options.includeOuter)
                root.push(state.dataSource.Executers[1]);

            return root;
        },
        getCorrespondentsTabTreeNodes (state) {
            let root = [];
            
            if (state.options.includeOuterEnterprises)
                root.push(state.dataSource.Correspondents[0]);

            if (state.options.includeJuridical)
                root.push(state.dataSource.Correspondents[1]);

            if (state.options.includePersons)
                root.push(state.dataSource.Correspondents[2]);

            return root;
        },
        getExecuterGroupsTabTreeNodes (state) {
            let tmp = Array.from(state.dataSource.ExecuterGroups);
            //нужно выставить allow
            return tmp;
        },
        getSelectedItems: (s) => s.selectedItems,
        getSelectedItemsIds (state) {
            let ids = [];

            state.selectedItems.forEach(item => {
                ids.push(item.type === 0 ? item.enterprise : item.workplaceId ?? item.enterprise);
            });

            return ids;
        },
        getExcludedItemsIds: (s) => s.excludedItemsIds,
        isHierarchical: (s) => s.options?.hierarchical ?? false
    }
}

export default selectMembersNew;