import Incoming from './lists/Incoming'
import NPA from './lists/NPA'
import Outcoming from './lists/Outgoing'
import Inner from './lists/Inner'
import Resolutions from './lists/Resolutions'
import Statement from './lists/Statement'

export default {
    namespaced: true,
    state: () => ({
        collection : null,
        status: [
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument",
                value: "-10"
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument",
                value: "-10"
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.NPADocument",
                value: "-10"
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                value: "-10"
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument",
                value: "-10"
            },
            {
                collection: "CitizenStatement",
                value: "0"
            },
            {
                collection: "ChancelleryResolution",
                value: "0"
            },
        ],
        searches: [],
        extendedFilters: [
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument",
                value: 
                {
                    Correspondent:
                    {
                        label: "Корреспондент",
                        value: []
                    },
                    ExecuterName:
                    {
                        label: "Исполнитель",
                        value: null
                    },
                    OutNumber:
                    {
                        label: "Исходящий_номер",
                        value: null
                    },
                    OutDateFrom: 
                    {
                        label: "Дата_исходящего_c",
                        value: null
                    },
                    OutDateTo: 
                    {
                        label: "Дата_исходящего_по",
                        value: null
                    },
                    HigherNumber:
                    {
                        label: "Входящий_номер_вышестоящего",
                        value: null
                    },
                    RecomDurationFrom:
                    {
                        label: "Рекомендуемый_срок_с",
                        value: null
                    },
                    RecomDurationTo:
                    {
                        label: "Рекомендуемый_срок_по",
                        value: null
                    },
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument",
                value: 
                {
                    Correspondent:
                    {
                        label: "Корреспондент",
                        value: []
                    },
                    ExecuterName:
                    {
                        label: "Исполнитель",
                        value: null
                    },
                    OutNumber:
                    {
                        label: "Исходящий_номер",
                        value: null
                    },
                    OutDateFrom: 
                    {
                        label: "Дата_исходящего_c",
                        value: null
                    },
                    OutDateTo: 
                    {
                        label: "Дата_исходящего_по",
                        value: null
                    },
                    HigherNumber:
                    {
                        label: "Входящий_номер_вышестоящего",
                        value: null
                    },
                    RecomDurationFrom:
                    {
                        label: "Рекомендуемый_срок_с",
                        value: null
                    },
                    RecomDurationTo:
                    {
                        label: "Рекомендуемый_срок_по",
                        value: null
                    },
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            },            
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.NPADocument",
                value: 
                {
                    Recipients:
                    {
                        label: "Получатель",
                        value: []
                    },
                    DocumentAuthor:
                    {
                        label: "Автор",
                        value: []
                    },                   
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                value: 
                {
                    Recipients:
                    {
                        label: "Получатель",
                        value: []
                    },
                    DocumentAuthor:
                    {
                        label: "Автор",
                        value: []
                    },                   
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            },
            {
                collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument",
                value: 
                {
                    DocumentAuthor:
                    {
                        label: "Автор",
                        value: []
                    },
                    ResolutionAuthor:
                    {
                        label: "Автор_резолюции",
                        value: []
                    },                   
                    Languages:
                    {
                        label: "Язык_документа",
                        value: []
                    },
                    CarrierType:
                    {
                        label: "Тип_носителя",
                        value: []
                    },
                }
            },
            {
                collection: "CitizenStatement",
                value: 
                {
                    Declarant_IIN_BIN:
                    {
                        label: "ИИН_БИН",
                        value: null
                    },
                    StQuestion:
                    {
                        label: "Характер_вопроса",
                        value: null
                    },
                    PerCategory:
                    {
                        label: "Категория_лица",
                        value: null
                    },
                    StLanguage:
                    {
                        label: "Язык_обращения",
                        value: null
                    },
                    StForm:
                    {
                        label: "Форма_обращения",
                        value: null
                    },
                    ExecuterWorkplaceId:
                    {
                        label: "Исполнитель",
                        value: null
                    },
                    ControlDateFrom:
                    {
                        label: "Срок_исполнения_с",
                        value: null
                    },
                    ControlDateTo:
                    {
                        label: "Срок_исполнения_по",
                        value: null
                    },
                }
            },
            {
                collection: "ChancelleryResolution",
                value: {}
            },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_EXTENDED_FILTERS (state, payload) {
            let extendedFilter = state.extendedFilters.find(x => x.collection == payload.collection);

            if (extendedFilter) {
                extendedFilter.value = payload.value;
            } else {
                state.extendedFilters.push(payload);
            }
        },
        PUSH_STATUS (state, payload) {
            let searchExists = state.status.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.status.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        }
    },
    actions: {
        initCollection({ commit, state, rootGetters }) {
            if (!state.collection)
            {
                let user = rootGetters['auth/getUserInfo'];

                if (user.permissions.includes("ChancelleryIncomingDocumentsList")) {
                    commit('SET_COLLECTION', "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument");
                    return;
                }

                if (user.permissions.includes("ChancelleryOutcomingDocumentsList")) {
                    commit('SET_COLLECTION', "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument");
                    return;
                }

                if (user.permissions.includes("ChancelleryInternalDocumentsList")) {
                    commit('SET_COLLECTION', "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument");
                    return;
                }

                if (user.permissions.includes("CitizenStatementList")) {
                    commit('SET_COLLECTION', "CitizenStatement");
                    return;
                }
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearches (state) {
            return state.searches;
        }, 
        getExtendedFilters: (s) => s.extendedFilters
    },
    modules: {
        incoming: Incoming,
        npa: NPA,
        outcoming: Outcoming,
        inner: Inner,
        resolutions: Resolutions,
        statement: Statement
    }
}