const addMeetingQuestion = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        subject: null,
        duration: null,
        speaker: null,

        members: []
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_SUBJECT(state, payload) {
            state.subject = payload;
        },        
        SET_DURATION(state, payload) {
            state.duration = payload;
        },
        SET_SPEAKER(state, payload) {
            state.speaker = payload;
        },
        SET_MEMBERS(state, payload) {
            state.members = payload;
        }
    },
    actions: {
        async open({ commit }, { members, question = null }) {
            commit('SET_SUBJECT', null);
            commit('SET_DURATION', null);
            commit('SET_SPEAKER', null);
            commit('SET_MEMBERS', members);
            commit('SET_VISIBLE', true);

            if (question) {
                commit('SET_SUBJECT', question.subject);
                commit('SET_DURATION', question.duration);
                commit('SET_SPEAKER', question.speaker);
            }

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ subject: state.subject, duration: state.duration, speaker: state.speaker });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getSubject: (s) => s.subject,
        getDuration: (s) => s.duration,
        getSpeaker: (s) => s.speaker,
        getMembers: (s) => s.members
    }
}

export default addMeetingQuestion;