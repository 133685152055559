<template>
    <v-app>
        <Overlay class="top-level" absolute />
        <v-expand-transition>
            <v-system-bar v-if="!connected" height="30" window color="red darken-1" class="no-connection-bar">
                <v-spacer></v-spacer>
                <v-icon color="white">fas fa-plug</v-icon>
                <span style="color: white;">Нет соединения с сервером</span>
                <v-spacer></v-spacer>
            </v-system-bar>
        </v-expand-transition>
    
        <component :is="layout">
            <router-view />
        </component>

    </v-app>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import { mapGetters} from 'vuex';
import Overlay from './components/Overlay';

    export default {
        name: 'App',
        components: {
            LoginLayout,
            MainLayout,
            Overlay,
        },
        data: () => ({
            customStyle: null
        }),
        computed: {
            connected() {
                return this.$store.getters.getBackendConnectionState === "Connected";
            },
            layout() {
                return this.$route.meta.layout || 'login-layout';
            },
            ...mapGetters(['isLanguageChanging']),
        },
        methods: {
            getLanguage() {
                switch (this.$i18n.locale)
                {
                    case "ru":
                        return 1;

                    case "kk":
                        return 0;

                    default:
                        return 1;
                }
            },
            callLangStyles() {

                if(this.customStyle == null){
                    this.customStyle = document.createElement('style');
                    document.head.appendChild(this.customStyle);
                }
                
                for (let index = 0; index < this.customStyle.sheet.cssRules.length; index++) {
                    
                    this.customStyle.sheet.deleteRule(index);
                }
                
                if (this.getLanguage() == 0){
                    
                    this.customStyle.sheet.insertRule(
                        `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Иә" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .cust-switch .v-input--switch__track:after { content: "Жоқ" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .error--text:not(.v-messages)::after, .chipAreaNoData::after, .time-picker.time-empty::after { content: "Міндетті өріс" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .vue__time-picker .dropdown .hours .hint::after { content: 'Сағат' }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .vue__time-picker .dropdown .minutes .hint::after { content: 'Минут' }`, 0
                    );
                }    
                else
                {
                    
                    this.customStyle.sheet.insertRule(
                        `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Да" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .cust-switch .v-input--switch__track:after { content: "Нет" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .error--text:not(.v-messages)::after, .chipAreaNoData::after, .time-picker.time-empty::after { content: "Обязательное поле" }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .vue__time-picker .dropdown .hours .hint::after { content: 'Часы' }`, 0
                    );
                    this.customStyle.sheet.insertRule(
                        `.v-application .vue__time-picker .dropdown .minutes .hint::after { content: 'Минуты' }`, 0
                    );
                }    
                          
            }
        },
        updated() {
            this.callLangStyles();
        }, 
        watch: {
            /*isLanguageChanging(){
                if(!this.isLanguageChanging){
                    this.callLangStyles();
                }
            }*/
        },
    };
</script>

<style lang="scss">
    @import "./assets/styles/common.css";
    @import "./assets/styles/notifystyle.css";

    .top-level {
        z-index: 1000 !important;
    }
</style>