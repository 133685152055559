import axios from "axios";
import i18n from '@/i18n';
import store from '../store';
import sys from '@/services/system';
import Vue from 'vue';

const httpAPI = axios.create();
var isLogoutNotificationActive = false;

var sessionId = sessionStorage.getItem('SessionId');

if (!sessionId) {
    sessionId = sys.generateUUID()
    sessionStorage.setItem('SessionId', sessionId);                          
}

httpAPI.defaults.headers.common['sessionId'] = sessionId;


httpAPI.interceptors.request.use(function (config) {
    if (config.method == "get" && config.url.includes('{') && config.url.includes('}') && (config.url.includes('#') || config.url.includes('&'))){
        let startJSON = config.url.indexOf("{");
        while (startJSON >= 0){
            let endJSON = config.url.indexOf("}",startJSON);
            let oldJson = config.url.substring(startJSON, endJSON);
            if (oldJson.includes('#') || oldJson.includes('&')){
                let newJson = oldJson.replaceAll('#', '').replaceAll('&', '');
                if (oldJson != newJson){
                    config.url = config.url.replace(oldJson, newJson);
                }
            }
            startJSON = config.url.indexOf("{", endJSON);
        }
    }
    return config;
  });

httpAPI.interceptors.response.use(
    // Do something with response data
    function (response) {
        const { config } = response;

        if(config?.skipErrorHandler === true)
            return response;

        if (response.data?.success === false) {
            Vue.prototype.$notify.alert(response?.data?.message);
            return null;
        }

        if (response.data?.success === true && response.data?.payload?.Result === "ERROR") {
            Vue.prototype.$notify.alert(response.data?.payload?.Message);
            return null;
        }

        return response;
    },
    // Do something with response error
    function (error) {
        const { config } = error;

        if(config?.skipErrorHandler === true)
            return Promise.reject(error);
        
        errorHandler(error);
        return null;
    }
);

const errorHandler = (error) => {

    if (error && typeof error.toJSON === "function" && error.toJSON().message === 'Network Error') {
        Vue.prototype.$notify.alert(i18n.t('Сетевое_подключение_недоступно_или_ограничено'));
    }

    const statusCode = error?.response?.status;

    if (axios.isCancel(error)) {
        console.log(error);
        Vue.prototype.$notify.warning(i18n.t('Действие_отменено'));
    }

    if (statusCode === 400) {
        if(error.response?.data?.type === "application/json") {
            const reader = new FileReader();
            reader.onload = function() {
                let failObj = JSON.parse(this.result);
                Vue.prototype.$notify.alert(failObj.message);
            };
            reader.readAsText(error?.response?.data);
        }
        else {
            Vue.prototype.$notify.alert(i18n.t('Некорректный_запрос'));
        }
    }

    if (statusCode === 401) {
        if (store.getters['auth/isAuthorized']) {
            let message = i18n.t('Необходимо_пройти_повторную_аутентификацию')

            if (error?.response?.data)
                message += `:<br/>${i18n.t('Отсутствует_токен_авторизации')}`;

            if (!isLogoutNotificationActive) {

                isLogoutNotificationActive = true;

                Vue.prototype.$notify.confirm(
                    i18n.t(message),
                    async () =>
                    {
                        await store.dispatch('auth/fetchEmergencyLogout');
                        isLogoutNotificationActive = false;
                    },
                    false,
                    {
                        labels: {
                            confirm: i18n.t('Аутентификация')
                        }
                    }
                );
            }
        }
        else {
            Vue.prototype.$notify.alert(error?.response?.data);
        }
    }

    if (statusCode === 404) {
        Vue.prototype.$notify.alert(i18n.t('Конечная_точка_не_найдена'));
    }

    if (statusCode === 451) {
        Vue.prototype.$notify.warning(error?.response?.data?.message);
    }

    if (statusCode === 500) {
        if (error.response?.data?.type === "text/plain") {
            const reader = new FileReader();
            reader.onload = function() {
                Vue.prototype.$notify.alert(this.result);
            };
            reader.readAsText(error?.response?.data);
        }
        else {
            Vue.prototype.$notify.alert(i18n.t('Ошибка_обработки_запроса'));
        }
    }
}

export { httpAPI, errorHandler };