import { httpAPI } from "@/api/httpAPI";

const executerAnswer = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        executionText: "",

        documents: [],
        selectedDocument: null,
        documentSearch: null,
        documentPage: 1,
        documentPerPage: 5,
        documentsTotal: 0,

        files: [],

        options: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTION_TEXT(state, payload) {
            state.executionText = payload;
        },
        //#region Для выбора документа
        SET_DOCUMENTS(state, payload) {
            state.documents = payload;
        },
        SET_SELECTED_DOCUMENT(state, payload) {
            state.selectedDocument = payload;
        },
        SET_DOCUMENT_SEARCH(state, payload) {
            state.documentSearch = payload;
        },
        SET_DOCUMENT_PAGE(state, payload) {
            state.documentPage = payload;
        },
        SET_DOCUMENT_TOTAL(state, payload) {
            state.documentsTotal = payload;
        },
        //#endregion
        SET_FILES(state, payload) {
            state.files = payload;
        },
        SET_OPTIONS(state, payload) {
            state.options = payload;
        }
    },
    actions: {
        async open({ commit, dispatch, rootGetters }, options = null) {
            //#region сбрасываем состояние
            commit('SET_OPTIONS', options);
            commit('SET_EXECUTION_TEXT', "");
            commit('SET_DOCUMENT_PAGE', 1);
            commit('SET_DOCUMENT_SEARCH', null);
            commit('SET_SELECTED_DOCUMENT', null);
            commit('SET_FILES', []);
            //#endregion

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);
            
            if (rootGetters['actionsource/isDataSourceCommon'] != true || options != null)
                await dispatch('fetchDocuments');

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ text: state.executionText, document: state.selectedDocument, files: state.files});
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async changeSearch({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_SEARCH', value);
            commit('SET_DOCUMENT_PAGE', 1);
            await dispatch('fetchDocuments');
        },
        async changePage({ commit, dispatch }, value) {
            commit('SET_DOCUMENT_PAGE', value);
            await dispatch('fetchDocuments');
        },
        async fetchDocuments({ commit, state, rootGetters }) {

            let isEuolResolution = rootGetters['actionsource/isEuolStatementDataSource'];
            let filter = null;
            let url = null;

            if (isEuolResolution) {
                filter = {
                    SearchStr: state.documentSearch,
                    OrderBy: 4,
                    OrderDir: true,
                    Limit: state.documentPerPage,
                    Offset: (state.documentPage - 1) * state.documentPerPage
                };
                url = `/api/correspondence/myoutgoinglist?filter=${JSON.stringify(filter)}`
            }
            else if (state.options) {
                filter = {
                    SearchStr: state.documentSearch,
                    DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                    Period: null,
                    RegState: 'Registered',
                    IncludeDuplicate: false,
                    AnswerForDIRORD: rootGetters['actionsource/getDocumentParentId'],
                    OrderBy: 3,
                    OrderDir: true,
                    Limit: state.documentPerPage,
                    Offset: (state.documentPage - 1) * state.documentPerPage
                };
                url = `/api/correspondence/documentselect?filter=${JSON.stringify(filter)}`
            }
            else {
                filter = {
                    SearchStr: state.documentSearch,
                    DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
                    OutgoingDocumentType: -1,
                    OrderBy: 4,
                    OrderDir: true,
                    Limit: state.documentPerPage,
                    Offset: (state.documentPage - 1) * state.documentPerPage
                };
                url = `/api/correspondence/list?filter=${JSON.stringify(filter)}`
            }

            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'isCommon': state.options ? true : false }
             });

            if (response) {
                commit('SET_DOCUMENTS', response.data.payload.data ?? []);
                commit('SET_DOCUMENT_TOTAL', response.data.payload.total);
            }
            else {
                commit('SET_DOCUMENTS', []);
                commit('SET_DOCUMENT_TOTAL', 0);
            }
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,

        getExecutionText: (s) => s.executionText,

        getDocuments: (s) => s.documents,        
        getDocumentSearch: (s) => s.documentSearch,
        getDocumentPage: (s) => s.documentPage,
        getDocumentPerPage: (s) => s.documentPerPage,
        getDocumentsTotal: (s) => s.documentsTotal,
        getSelectedDocument: (s) => s.selectedDocument,

        getFiles: (s) => s.files,
        getOptions: (s) => s.options
    }
}

export default executerAnswer;