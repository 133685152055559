<template>
    <div>
        <router-view />
        <UserReplacePassword ref="UserReplacePasswordRef" />
    </div>
</template>

<script>
import UserReplacePassword from '@/components/dialogs/UserReplacePassword';

export default {
    name: "Logo",
    components:{
        UserReplacePassword,
    },
}
</script>