const notifySignErrorConfirm = {
    namespaced: true,
    state: {
        visible: false,
        type: null,
        resolve: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_TYPE(state, payload) {
            state.type = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        }
    },
    actions: {
        async open({ commit }, type) {
            commit('SET_TYPE', type);
            commit('SET_VISIBLE', true);

            return new Promise((resolve) => {
                commit('SET_RESOLVE', resolve);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ result: 'ok' });
        },
        async retry({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ result: 'retry' });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ result: 'cancel' });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getType: (s) => s.type
    }
}

export default notifySignErrorConfirm;