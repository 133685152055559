export default {
    namespaced: true,
    state: () => ({
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : [ "regdate" ],
            sortDesc : [ true ],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
        category: "0",
        status: new Date().getFullYear().toString(),
        tableSearch: ""
    }),
    mutations: {
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_CATEGORY (state, payload) {
            state.category = payload;
        },
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        SET_TABLE_SEARCH (state, payload) {
            state.tableSearch = payload;
        }
    },
    getters: {
        GetTableOptions: (s) => s.tableOptions,
        GetCategory: (s) =>  s.category,
        GetStatus: (s) => s.status,
        GetTableSearch: (s) => s.tableSearch
    }
}