<template>
    <v-dialog
        v-if="visible"
        v-model="visible" 
        transition="dialog-top-transition"
        scrollable 
        persistent
        max-width="1000"
    >

        <v-card class="modal-maincard">
            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card
                    flat
                >
                    <!-- Downloading -->
                    <v-card-text
                        v-if="loading"
                    >
                        <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                        >
                            {{ $t("Загрузка...") }}
                        </v-col>

                        <v-col cols="12">
                            <v-progress-linear
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>
                        </v-col>

                    </v-card-text>
                    <!-- Content -->
                    <v-card-text v-else>

                        <div class="form-box-title title-with-right-part">
                            <span>
                                {{ $t("Группы_исполнителей") }}
                            </span>
                            <!-- <div class="twrp-content">
                                <v-text-field
                                    v-model="search"
                                    @input="onSearchInput"
                                    :placeholder="$t('Поиск')"
                                    hide-details
                                    dense
                                    clearable
                                    @click:clear="search = null"
                                    class="twrp-search"
                                    append-icon="fas fa-search"
                                >
                                </v-text-field>
                            </div> -->
                        </div>

                        <v-list
                            class="modal-select-list"
                            dense
                            outlined
                            flat
                        >
                            <v-list-item
                                v-for="item in executerGroups"
                                :key="item.id"
                                :value="item"
                                v-on:dblclick="onEditExecuterGroup(item)"
                                class="pseudo-selectable-v-list-item"
                            >
                                <v-list-item-avatar>
                                    <v-icon>
                                        mdi-account-group
                                    </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t('Количество_исполнителей') }} - {{ item.executercount }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <div @click.stop="onDeleteExecuterGroup(item)">
                                        <v-icon
                                            small
                                            v-tooltip.left-center="$t('Удалить')"                                                    
                                        >
                                            fas fa-trash
                                        </v-icon>
                                    </div>
                                </v-list-item-icon>

                            </v-list-item>

                            <v-list-item
                                v-if="data.length < $options.MAX_GROUPS_COUNT"
                                class="create-v-list-item pseudo-selectable-v-list-item"
                                @click.stop="onEditExecuterGroup"
                            >
                                <v-list-item-avatar>
                                    <v-icon
                                        small
                                    >
                                        fas fa-plus
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Добавить
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>

                    </v-card-text>

                </v-card>
            </v-card-text>
            
            <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{ $t("Закрыть") }}
                    </v-btn>

                </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash';

export default {
    name: "PersonalExecuterGroupsDlg",
    MAX_GROUPS_COUNT: 6,
    data: () => ({
        resolve: null,
        title: "Персональные_группы_исполнителей",
        visible: false,
        loading: false,
        data: [],
        search: null
    }),
    computed: {
        executerGroups() {
            if (this.data.length) {
                return this.data.slice(0, this.$options.MAX_GROUPS_COUNT);
            }

            return [];
        }
    },
    methods: {
        async open() {
            this.search = null;
            this.visible = true;

            this.loading = true;
            //#region any downloads starts here
            await this.fetchGroups(this.search);
            //#endregion any downloads ends here
            this.loading = false;

            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        cancel() {
            this.visible = false;
            this.resolve();
        },
        async fetchGroups(search) {
            let filter = JSON.stringify({ SearchStr: search });

            let response = await httpAPI({
                url: `api/references/ownexecutergroups?filter=${filter}`,
                method: 'GET'
            });

            this.data = response?.data?.payload?.data ?? [];
        },
        onSearchInput: _.debounce(async function () {
            await this.fetchGroups(this.search);
        }, 250),
        async onEditExecuterGroup(item) {
            try
            {
                this.visible = false;
                let executerGroup = await this.$store.dispatch('dialogs/executersGroup/open', item ?? { id: null });

                if (Object.prototype.hasOwnProperty.call(executerGroup, 'IsOwn'))
                    executerGroup.IsOwn = true;

                let saveResponse = await httpAPI({
                    url: `api/actions/save?type=Documents.ExecuterGroup`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ parent: executerGroup, childrens: null }) },
                });

                if (saveResponse) {
                    await this.fetchGroups();
                    this.$notify.success(saveResponse.data.message);
                }
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                this.visible = true;
            }
        },
        async onDeleteExecuterGroup(item) {
            this.$notify.confirm(
                this.$t('Вы_действительно_хотите_удалить_группу_исполнителей'),
                async () =>
                {
                    try
                    {
                        let deleteResponse = await httpAPI({
                            url: `api/actions/delete?type=Documents.ExecuterGroup&id=${item.id}`,
                            method: 'DELETE',
                            headers: { 'Content-Type': 'application/json' }
                        });

                        if (deleteResponse) {
                            await this.fetchGroups();
                            this._vm.$notify.success(deleteResponse.data.payload.Message);
                        }
                    }
                    catch (ex)
                    {
                        console.log(ex);
                    }
                }
            );
        }
    }
}
</script>

<style scoped>
    .pseudo-selectable-v-list-item {
        cursor: pointer;
    }
    .pseudo-selectable-v-list-item:hover {
        background: #F5F5F5;
    }
    .create-v-list-item {
        height: 50px!important;
    }
</style>