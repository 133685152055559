const setDocumentControl = {
    namespaced: true,
    state: {
        visible: false,

        from: null,
        text: '',
        controlDate: null,

        resolve: null,
        reject: null,

        executers: []
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_FROM(state, payload) {
            state.from = payload;
        },
        SET_TEXT(state, payload) {
            state.text = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTERS(state, payload) {
            state.executers = payload;
        },
        SET_CONTROLDATE(state, payload) {
            state.controlDate = payload ? this._vm.$moment(payload) : null;
        },
    },
    actions: {
        async open({ commit }, { controlDate }) {
            commit('SET_VISIBLE', true);
            commit('SET_FROM', null);
            commit('SET_TEXT', '');
            commit('SET_CONTROLDATE', controlDate);
            commit('SET_EXECUTERS', []);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ from: state.from, text: state.text, executers: state.executers, controldate: (state.controlDate? state.controlDate.format('YYYY-MM-DD') : null) });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },

    },
    getters: {
        isVisible: (s) => s.visible,
        isValid: () => true,
        getFrom: (s) => s.from,
        getText: (s) => s.text,
        getExecuters: (s) => s.executers,
        getControlDate: (s) => s.controlDate ? s.controlDate.format('YYYY-MM-DD') : '',
    }
}

export default setDocumentControl;