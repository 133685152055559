import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n';

const userAgreement = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        viewonly: false,
        tabContent:  { 
            confidential: null, 
            termsUse: null,
            personalData: null
        },
        check1: false,
        check2: false,
        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_CHECK1(state, payload) {
            state.check1 = payload;
        },
        SET_CHECK2(state, payload) {
            state.check2 = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_VIEWONLY(state, payload){
            state.viewonly = payload;
        },
        SET_CONFIDENTIAL(state, payload){
            state.tabContent.confidential = payload;
        },
        SET_TERMUSE(state, payload){
            state.tabContent.termsUse = payload;
        },
        SET_PERSONALDATA(state, payload){
            state.tabContent.personalData = payload;
        },
    },
    actions: {
        async open({ commit },{viewonly}) {
            commit('SET_VIEWONLY', viewonly);
            commit('SET_CHECK1', false);
            commit('SET_CHECK2', false);
            commit('SET_VISIBLE', true);
            commit('SET_LOADING', true);

            let responseConfidential = await httpAPI({
                url: `api/references/geteulapart?type=${'confidential'}&lang=${i18n.locale}`,
                method: 'GET'
            });

            let responseTermsUse = await httpAPI({
                url: `api/references/geteulapart?type=${'termsUse'}&lang=${i18n.locale}`,
                method: 'GET'
            });

            let responsePersonalData = await httpAPI({
                url: `api/references/geteulapart?type=${'personalData'}&lang=${i18n.locale}`,
                method: 'GET'
            });

            if (responseConfidential){
                commit('SET_CONFIDENTIAL', responseConfidential.data);
            }

            if (responseTermsUse){
                commit('SET_TERMUSE', responseTermsUse.data);
            }

            if (responsePersonalData){
                commit('SET_PERSONALDATA', responsePersonalData.data)
            }


            commit('SET_LOADING', false);
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_LOADING', true);
            try
            {
                let response = await httpAPI({
                    url: '/api/ems/setownconsent',
                    method: 'get',
                });

                if (response)
                {
                    commit('SET_VISIBLE', false);
                    state.resolve(response.data.payload.Message);
                }
            }
            catch (ex){
                commit('SET_LOADING', false);
            }
            finally{
                commit('SET_LOADING', false);
            }
        },

        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getViewOnly: (s) => s.viewonly,
        getTabContent: (s) => s.tabContent,
        getCheck1: (s) => s.check1,
        getCheck2: (s) => s.check2,
    }
}    

export default userAgreement;