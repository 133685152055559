/*05.08.2022 Добавили замену дефолтнтых фраз интерфейса data table, только тех, которые на виду, при необходимости можно раширить число локалей*/
import "@fortawesome/fontawesome-free/css/all.css";
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from '@/locales/stock/ru.js';
import kk from '@/locales/stock/kk.js';
import VTooltip from 'v-tooltip';
import IntegroLogo from '@/components/IntegroLogoIcon.vue';
import VueI18n from 'vue-i18n'

Vue.use(Vuetify);
Vue.use(VueI18n);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            integroIcon: {
                component: IntegroLogo,
            }
        },
    },
    lang: {
        locales: { ru, kk },
        current: localStorage.getItem('AppLocale') ?? 'kk',
    },
    /*change color scheme*/
    theme: {
        themes: {
        light: {
            error: '#a94442',
        },
        },
    },
});

Vue.use(VTooltip, {
    defaultBoundariesElement: document.body,
    popover: {
        defaultTrigger: 'hover'
    },
});