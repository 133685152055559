const changeEuolStatementUniqueNumber = {
    namespaced: true,
    state: {
        visible: false,

        resolve: null,
        reject: null,

        currentNumber: "",
        newNumber: ""
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_CURRENT_NUMBER(state, payload) {
            state.currentNumber = payload;
        },
        SET_NEW_NUMBER(state, payload) {
            state.newNumber = payload;
        }
    },
    actions: {
        async open({ commit }, current) {
            commit('SET_CURRENT_NUMBER', current);
            commit('SET_NEW_NUMBER', "");
            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.newNumber);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getCurrentNumber: (s) => s.currentNumber,
        getNewNumber: (s) => s.newNumber
    }
}

export default changeEuolStatementUniqueNumber;