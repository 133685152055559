const changeControl = {
    namespaced: true,
    state: {
        visible: false,

        resolve: null,
        reject: null,

        reason: null,
        date: null,

        minDate: null,
        maxDate: null,

        supposedDate: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_REASON(state, payload) {
            state.reason = payload;
        },
        SET_DATE(state, payload) {
            state.date = payload ? this._vm.$moment(payload) : null;
        },
        SET_MIN_DATE(state, payload) {
            state.minDate = this._vm.$moment(payload).add(1, 'days');
        },
        SET_MAX_DATE(state, payload) {
            state.maxDate = this._vm.$moment(payload);
        },
        SET_SUPPOSED_DATE(state, payload) {
            let today = this._vm.$moment(new Date());
            // присваем минимальную дату продления в рекомендуемую
            let supposedDate = this._vm.$moment(payload);

            //если минимальная дата была раньше, чем сегодня
            if (payload.isBefore(today)) {
                let tmp = today.add(3, 'days');

                //если текущая дата + 3 дня раньше максимально возможной даты, записываем ее в рекомендуемую
                if (tmp.isBefore(state.maxDate)) {
                    supposedDate = tmp;
                }
            }
            //если минимальная дата сегодня или позже
            else {
                let tmp = this._vm.$moment(payload).add(3, 'days');

                //если минимальная дата продления + 3 дня раньше максимально возможной даты, записываем ее в рекомендуемую
                if (tmp.isBefore(state.maxDate)) {
                    supposedDate = tmp;
                }
            }
            
            state.supposedDate = supposedDate;
        }
    },
    actions: {
        async open({ commit, state }, { min, max }) {
            commit('SET_MIN_DATE', min);
            commit('SET_MAX_DATE', max);
            commit('SET_REASON', null);
            commit('SET_DATE', null);
            commit('SET_SUPPOSED_DATE', state.minDate);

            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ date: state.date.format('YYYY-MM-DD'), reason: state.reason });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isValid: (s) => s.date && s.reason?.length,
        getReason: (s) => s.reason,
        getDate: (s) => s.date?.format('YYYY-MM-DD') ?? null,
        getMinDate: (s) => s.minDate.format('YYYY-MM-DD'),
        getMaxDate: (s) => s.maxDate.format('YYYY-MM-DD'),
        getSupposedDate: (s) => s.supposedDate.format('DD.MM.YYYY')
    }
}

export default changeControl;