import chancelleryCounters from "./lists/chancellery-counters";
import euolCounters from "./lists/euol-counters";
import nomenclatures from "./lists/nomenclatures";
import contragents from "./lists/contragents";
import executerGroups from "./lists/executer-groups";
import orderCounters from "./lists/order-counters";

const settings = {
    namespaced: true,
    state: () => ({
        collection : null,
        statuses:
        [
            {
                collection: "Chancellery.Counter",
                value: "All"
            },
            {
                collection: "CitizenStatements.EuolCounter",
                value: "All"
            },
            {
                collection: "Chancellery.Nomenclature",
                value: "All"
            },
            {
                collection: "Chancellery.Contragent",
                value: "Enterprise"
            },
            {
                collection: "Documents.ExecuterGroup",
                value: null
            },
            {
                collection: "Orders.Counter",
                value: null
            },
        ],
        searches: [
            {
                collection: "Chancellery.Counter",
                value: null
            },
            {
                collection: "CitizenStatements.EuolCounter",
                value: null
            },
            {
                collection: "Chancellery.Nomenclature",
                value: null
            },
            {
                collection: "Chancellery.Contragent",
                value: null
            },
            {
                collection: "Documents.ExecuterGroup",
                value: null
            },
            {
                collection: "Orders.Counter",
                value: null
            },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.searches.push(payload);
            }
        }
    },
    actions: {
        initCollection({ commit, state, getters }) {
            if (!state.collection) {
                if (getters.user.permissions.includes("Administration"))
                    commit('SET_COLLECTION', "Chancellery.Counter");

                if (getters.user.permissions.includes("Archivarius"))
                    commit('SET_COLLECTION', "Chancellery.Nomenclature");
            }
        }
    },
    getters: {
        getCollection: (s) => s.collection,
        getStatus: (s) => s.statuses.find(x => x.collection === s.collection)?.value,
        getSearch: (s) => s.searches.find(x => x.collection === s.collection)?.value,
        user: (state, getters, rootState, rootGetters) => rootGetters['auth/getUserInfo'],
    },
    modules: {
        chancelleryCounters,
        euolCounters,
        nomenclatures,
        contragents,
        executerGroups,
        orderCounters
    }
};

export default settings;