<template>
    <div>
        <!--Шапка-->
        <div class="header">
            <div class="header-wrapper">
                <component :is="logoComponentName" />
                
                <div class="top-menu">
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        mobile-breakpoint="0"
                        slider-size="3"
                        v-show="!isLanguageChanging"
                    >
                        <v-tabs-slider color="#E43958"></v-tabs-slider>
                
                        <v-tab
                            v-for="chapter in myChapters"
                            :key="chapter.name"
                            :to="chapter.route"
                            active-class="top-menu-active-item"
                        >
                            {{ $t(chapter.name) }}
                        </v-tab>
                    </v-tabs>
                </div>

                <div class="personal">
                    <div @click.stop="drawerSettings = !drawerSettings">
                        <v-icon
                            v-if="!ownPhoto || ownPhoto.length == 0"
                            class="p-user-i"
                        >
                            fas fa-user-circle
                        </v-icon>
                        <v-img
                            v-else
                            :src="ownPhoto"
                            class="p-user-photo"
                        >
                        </v-img>
                    </div>
                    <div class="el user-menu mail-down">
                        <div class="user-field">
                            <div>
                                <span class="user-name">
                                    <span class="uname" v-tooltip.top-center="userInfo.name">{{ userInfo.name }}</span>
                                    <span class="user-workplace">{{ userInfo.position }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-popover trigger="click">
                        <v-btn
                            class="p-lang-select-btn"
                            outlined
                            fab
                            color="white"
                            x-small
                        >{{ locales.find(el => el.id == locale).shortValue }}</v-btn>
                        <template slot="popover">
                            <ul class="ribbon-button-submenu" >
                                <li 
                                    v-for="(item, index) in locales"
                                    :key="index"
                                    :class="[locale != item.id ? 'p-lang-fake-icon-padd' : '']"
                                    v-close-popover
                                    @click="onLocaleInput(item)">
                                    <v-icon v-if="locale == item.id" left>fas fa-check</v-icon>
                                    {{ item.value }}   
                                </li>
                            </ul>
                            <span class="ribbon-button-submenu-title">{{ $t('Выбор_языка_интерфейса') }}</span>
                        </template>
                    </v-popover>
                    <i 
                        @click.stop="drawerSettings = !drawerSettings"
                        class="fas fa-ellipsis-v p-sidebar-i" 
                        v-tooltip.left-center="$t('Настройки')"
                    ></i>
                    
                </div>
            </div>
            <UserReplacePassword ref="UserReplacePasswordRef" />
        </div>
        <v-navigation-drawer
                v-model="drawerSettings"
                absolute
                right
                temporary
                max-width="330"
                width="330"
                class="drawerSettings"
            >
                          
            <v-list
                dense 
                class="drawerMenu"
            >
                <v-list-item-group
                    v-model="groupSettings"
                    color="#E43958"
                    mandatory
                >
                    <v-list-item style="display:none;"></v-list-item>

                    <v-list-item @click="goToProfileDlg">
                        <v-list-item-icon>
                            <v-icon>fas fa-address-card</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Профиль') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="openPersonalExecuterGroupsDlg">
                        <v-list-item-icon>
                            <v-icon>mdi-account-group</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Мои_исполнители') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider class="mt-2 mb-2"></v-divider>

                    <v-list-item @click="userReplacePasswordWindow()">
                        <v-list-item-icon>
                            <v-icon>fas fa-exchange-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Сменить_пароль') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="fetchLogout">
                        <v-list-item-icon>
                            <v-icon>fas fa-sign-out-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('Выход') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
            </v-list>

            <template v-slot:append>
                <v-divider class="mt-2 ml-4 mr-4"></v-divider>
                <v-expansion-panels 
                    v-model="loginExpandPanel"
                    flat
                    multiple
                    tile
                    class="lastInputsPanel"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ $t('Информация_о_последних_входах') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list class="pt-0" dense >
                                <v-list-item v-for="(item, index) in lastLogins"
                                    :key="index"
                                    :value="item"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-if="item.AuthState==0" class="success-color">mdi-account-check</v-icon>
                                        <v-icon v-else class="no-success-color">mdi-account-cancel</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{formatDate(item.AuthDateTime)}}, {{item.UserIP}}</v-list-item-title>
                                        <v-list-item-subtitle :class="item.AuthState==0 ? 'success-color' : 'no-success-color'">{{getAuthState(item)}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>    
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
        </v-navigation-drawer>
        <!-- <DashboardDlg ref="DashboardDlgRef" /> -->
        <ProfileDlg ref="ProfileDlgRef" />
        <PersonalExecuterGroupsDlg ref="PersonalExecuterGroupsDlgRef" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/i18n';
import UserReplacePassword from '@/components/dialogs/UserReplacePassword';
// import DashboardDlg from '@/components/dialogs/DashboardDlg';
import ProfileDlg from '@/components/dialogs/ProfileDlg';
import PersonalExecuterGroupsDlg from '@/components/dialogs/PersonalExecuterGroupsDlg';
import HeaderLogoC4 from '@/components/HeaderLogoC4';
import HeaderLogoC5 from '@/components/HeaderLogoC5';
import { httpAPI } from '@/api/httpAPI';
import sys from '@/services/system';

export default {
    data: () => ({
      //tab: null,
      prevTab: null,
      drawerSettings: false,
      groupSettings: 0,
      loginExpandPanel: [0],
      ownPhoto: '',
      lastLogins: [],
      locales: [ { id: 'kk', value: 'Қазақ', shortValue: 'Қаз' }, { id: 'ru', value: 'Русский', shortValue: 'Рус' } ],
    }),
    name: "Header",
    components:{
        UserReplacePassword,
        HeaderLogoC4,
        HeaderLogoC5,
        // DashboardDlg,
        ProfileDlg,
        PersonalExecuterGroupsDlg
    },
    computed: {
        ...mapGetters('header',
        {
            myChapters: 'getMyChapters'
        }),
        ...mapGetters(['isLanguageChanging']),
        logoComponentName(){            
            if (process.env.VUE_APP_CONFIGURATION == 'C5')
                return 'HeaderLogoC5';

            return 'HeaderLogoC4';
        }, 
        userInfo() {
            let userData = this.$store.getters['auth/getUserInfo'];
            let userName = userData.userName.replace(/ *\([^)]*\) */g, "");
            let userPosition = userData.userName.match(/\((.*)\)/)?.pop() ?? "";
            if (userData.warningMessagePassword)
                this.$notify.warning(this.$t('До_окончания_срока_действия_пароля_осталось_value_дней.message', {value: userData.passwordDaysLeft }));
            return { name: userName, position: userPosition };
        },
        tab: {
            get: function() {
                return this.$store.getters['header/getTab'];
            },
            set: function(v) {
                this.$store.commit('header/SET_TAB', v);
            }
        },
        locale() {
            return i18n.locale;
        },
    },
    methods:{
        ...mapActions('auth', ['fetchLogout']),
        async userReplacePasswordWindow() {
            //await this.$refs.UserReplacePasswordRef.open();
            try {
                let rez = await this.$store.dispatch('dialogs/userReplacePassword/open', {showmessage:false})
                if (rez)
                    this.$notify.success(rez);
            }
            catch(ex)
            {
                console.log(ex);
            }
        },
        async dashboardWindow() {
            await this.$refs.DashboardDlgRef.open();
        },
        async goToProfileDlg(){
            this.drawerSettings = false;
            await this.$refs.ProfileDlgRef.open();
        },
        async openPersonalExecuterGroupsDlg() {
            this.drawerSettings = false;
            await this.$refs.PersonalExecuterGroupsDlgRef.open();
        },
        formatDate(value){
             var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY HH:mm');
            return formatedDate ?? "";
        },
        getAuthState(item){
            return this.$t(item.AuthState==0 ? "Успешный_вход":"Неудачная_попытка_входа"); 
        },
        async getPhoto(){
            let url = `/api/ems/getownphoto`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) {
                this.ownPhoto=response?.data?.payload?.Data?.Object ?? '';
            }
        },
        async getLastLogins(){
            let url = `/api/ems/getownlastlogins`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response) {
                this.lastLogins=response?.data?.payload ?? [];
            }
        },
        async initialize() {
            await this.getPhoto();
            await this.getLastLogins();
        },
        async onLocaleInput(locale) {
            this.$store.commit('SET_LANGUAGE_CHANGING', true);

            await httpAPI({
                url: `/api/actions/changeuserlanguage`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { Content: locale.id },
                skipErrorHandler: true
            });
            
            localStorage.setItem('AppLocale', locale.id)
            i18n.locale = locale.id;
            this.$vuetify.lang.current = locale.id;

            this.$store.commit('SET_LANGUAGE_CHANGING', false);
        },
    },
    watch: {
        groupSettings () {
            this.drawerSettings = false
        },
        isLanguageChanging(val){
            if (val){
                this.prevTab = this.tab;
                this.tab = null
            }
            else
                this.tab = this.prevTab;
        }
    },
    created() {
        (async() => {
            this.initialize();
        })();      
    },
    mounted() {
        this.$eventBus.$on('update_profile', async () => { await this.getPhoto(); });
    },
    beforeDestroy() {
        this.$eventBus.$off('update_photo');
    }
};
</script>