import Vue from 'vue'

const Configurations = Object.freeze({
    C4: 8,
    C5: 32
});

function checkAccess(chapter, configuration, permissions) {
    switch (chapter.route)
    {   
            case "/":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && Array.from(permissions).includes('Documents');
            case "/projects":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('DocumentsDrafts');
            case "/requests":
                return configuration === Configurations.C5 && permissions && Array.from(permissions).includes('CityStatementCenter');
            case "/inwork":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('MyDocuments');
            case "/handled":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('MyDocuments');
            case "/chancellary":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('Chancellery');
            case "/correspondence":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('Documents');
            case "/events-management":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('Meetings');
            case "/archive":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('Documents');
            case "/dsp":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('DocumentsDSP');
            case "/executioncontrol":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('ExecutionControl');
            case "/tasks":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && Array.from(permissions).includes('Documents');
            case "/reports":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('Chancellery');
            case "/settings":
                return ((configuration === Configurations.C4 || configuration === Configurations.C5) && permissions) 
                    ? (permissions.includes('Administration') && permissions.includes('References')) || permissions.includes('Archivarius')
                    : false;
            case "/npa":
                return (configuration === Configurations.C4 || configuration === Configurations.C5) && permissions && Array.from(permissions).includes('NPA');
    }
}

const header =
{
    namespaced: true,
    state: {
        tab: null,
        chapters: [
            { 
                name: "Главная",
                route: "/",
            },
            { 
                name: "Проекты",
                route: "/projects",
            },
            { 
                name: "Заявки",
                route: "/requests",
            },
            { 
                name: "В_работе",
                route: "/inwork",
            },
            { 
                name: "Обработанные",
                route: "/handled",
            },
            { 
                name: "Канцелярия",
                route: "/chancellary",
            },
            { 
                name: "Корреспонденция",
                route: "/correspondence",
            },
            { 
                name: "НПА",
                route: "/npa"
            },
            { 
                name: "Мероприятия",
                route: "/events-management",
            },
            { 
                name: "Архив",
                route: "/archive",
            },            
            { 
                name: "ДСП",
                route: "/dsp",
            },
            { 
                name: "Контроль",
                route: "/executioncontrol",
            },
            {
                name: "Задачи",
                route: "/tasks",
            },
            { 
                name: "Отчеты",
                route: "/reports",
            },
            { 
                name: "Справочники",
                route: "/settings",
            }
        ]
    },
    mutations: {
        SET_TAB (state, payload) {
            state.tab = payload;
        },
    },
    actions: {
        
    },
    getters: {
        getMyChapters: (state, getters, rootState, rootGetters) =>
        {
            let userInfo = rootGetters['auth/getUserInfo'];
            let items = state.chapters.reduce( function (items, chapter) {
                if (checkAccess(chapter, userInfo?.configuration, userInfo?.permissions))
                    items.push({ name: chapter.name, route: chapter.route });
                return items;
            }, []);

            if (Vue.config.devtools) {
                items.push({ name: "Песочница", route: "/sandbox" });
            }

            return items;
        },
        getTab: s => s.tab
    }
}

export default header;