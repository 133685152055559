<template>
    <v-overlay :value="overlayVisible" absolute>
        <span class="fs16pt">{{ $t(overlayText) }}</span>
        
        <v-progress-linear 
            color="#a94442"
            opacity="1" 
            :height="!getProgress ? 6 : 12"             
            rounded  
            striped 
            :indeterminate="!getProgress"
            :value="getProgress"        
        >
            <template v-slot:default="{ value }">
                <strong v-if="getProgress">{{ value }}%</strong>
            </template>
        </v-progress-linear>

    </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Overlay',
    data: () => ({}),
    computed: {
        ...mapGetters(['overlayVisible', 'overlayText', 'getProgress']),
    },
};
</script>

<style lang="scss" scoped>
.fs16pt {
    font-size: 16pt;
}
</style>
