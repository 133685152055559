import { httpAPI } from "@/api/httpAPI";

import i18n from '@/i18n'

export default {
    namespaced: true,
    state: () => ({
        references: [],

        workplaces: [],
        workplacesSyncAt: 0,

        signers: [],
        signersSyncAt: 0,

        departmentNomenclatures: [],
        departmentNomenclaturesSyncAt: 0,

        enterpriseNomenclatures: [],
        enterpriseNomenclaturesSyncAt: 0,

        allNomenclatures: [],
        allNomenclaturesSyncAt: 0,

        contractors: [],
        contractorsSyncAt: 0,

        outerPersons: [],
        outerPersonsSyncAt: 0,

        outerJuridical: [],
        outerJuridicalSyncAt: 0,

        executerGroups: [],
        executerGroupsSyncAt: 0,

        executersForMemberDlg: [],
        executersForMemberDlgSyncAt: 0,

        innerActItemsEnterpriseExecuters: [],
        innerActItemsEnterpriseExecutersSyncAt: 0,

        executerGroupExecuters: [],

        newDialogInnerExecuters:[],
        newDialogInnerExecutersSyncAt:0,

        newDialogApplyResolutions:[],
        newDialogApplyResolutionsSyncAt:0,

        newDialogSigners:[],
        newDialogSignersSyncAt:0,

        newDialogOuterSigners:[],
        newDialogOuterSignersSyncAt:0,

        newDialogOuterExecuters:[],
        newDialogOuterExecutersSyncAt:0,

        newDialogESEDOExecuters:[],
        newDialogESEDOExecutersSyncAt:0,

        newDialogJurExecuters:[],
        newDialogJurExecutersSyncAt:0,

        newDialogFizExecuters:[],
        newDialogFizExecutersSyncAt:0,

        newDialogGroupExecuters:[],
        newDialogGroupExecutersSyncAt:0,

        enterprises:[],
        enterprisesSyncAt:0,

        сacheTimeInMinutes: 5,
        IQalaDeclarantCategoryTypes: 
        [
            { id: 1, Value: "Физическое_лицо" },
            { id: 2, Value: "Индивидуальный_предприниматель" },
            { id: 3, Value: "Юридическое_лицо" },
            { id: 4, Value: "ГКП" },
            { id: 5, Value: "Акционерное_общество" },
            { id: 6, Value: "Товарищество_с_ограниченной_ответственностью" },
        ],
        IQala0103X017EnergySupplyOrgsList: 
        [
            { id: 0, Value: "ТОО_Астанаэнергосбыт" },
            { id: 1, Value: "ТОО_Астана_қалалық_жарық»" },
            { id: 2, Value: "ТОО_Теміржолэнерго" },
            { id: 3, Value: "ТОО_Smart_Energy_Hub" },   
            { id: 4, Value: "ТОО_Digital_Energy_Solutions" },  
            { id: 5, Value: "ТОО_Корпорация_ЯСИН" },  
            { id: 6, Value: "ТОО_Kaz_Energy_Resources" },           
        ],
        DeclarantCategoryTypes: 
        [        
            { id: 1, Value: "Физическое_лицо" },
            { id: 2, Value: "Юридическое_лицо" }
        ],
        AdjustmentTypes:
        [
            { id: false, Value: "Последовательно" },
            { id: true, Value: "Параллельно" }
        ],
        ControlTypes: {
            0: 'Не_указано',
            1: 'Контроль',
            2: 'Особый_контроль',
            100: 'Снят_с_контроля'
        },
        IncomingLinksTypes:
        [
            { id: 1, Value: "Исходящий_ответный_документ" },  
            { id: 2, Value: "Является_ответом_на_исходящий_документ" },
            { id: 101, Value: "Входящий_ответный_документ" },
            { id: 102, Value: "Является_ответом_на_входящий_документ" }
        ],
        InnerLinksTypes:
        [
            { id: 9, Value: "Связанный_документ" }
        ],
        OutcomingLinksTypes:
        [
            { id: 1, Value: "Документ_на_который_дается_ответ" },
            { id: 2, Value: "Является_ответом_на_текущий_документ" }
        ],        
        //Avrora.Objects.Modules.Docflow.Enumerators.DocumentTypes
        DocumentTypes:
        {
            1:  "ВсеU",
            2:  "Проект",
            3:  "Входящий_документ",
            4:  "Исходящий_документ",
            5:  "Внутренний_документ",
            6:  "Акт",
            7:  "Договор",
            8:  "Организационно_распорядительный_документ",
            9:  "Обращение_граждан",
            10: "Контрольный_документ",
            11: "Заключение",
            12: "Обращение",
            13: "Ответ_на_обращение",
            14: "Заявка",
            17: "НПА",
            18: "Протокол"
        },
        EDSTypes:
        [
            { id: "0", Value: "Простая_подпись" },
            { id: "1", Value: "УЦГО" },
            { id: "2", Value: "НУЦ" }
        ],
        EDSStatusesTypes:
        [
            { id: "-1", Value: "Статус_подписи_неопределен" },
            { id: "1", Value: "Положительный_результат_ЭЦП" },
            { id: "0", Value: "Отрицательный_результат_ЭЦП" }
        ],
        avaibleStatuses:
        {
            work: {
                text: "В_работе",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            today: {
                text: "Срок_сегодня",
                style: {
                    'background-color': "#f57731"
                }
            },
            expired: {
                text: "Просрочено",
                style: {
                    'background-color': "#F04747"
                }
            },
            consideration: {
                text: "На_рассмотрении",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            considerated: {
                text: "Рассмотрен",
                style: {
                    'background-color': "#43B581"
                }
            },
            considerationExpired: {
                text: "Не_рассмотрено_в_срок",
                style: {
                    'background-color': "#F04747"
                }
            },
            done: {
                text: "Выполнен",
                style: {
                    'background-color': "#43B581"
                }
            },
            doneExpired: {
                text: "Выполнен_не_в_срок",
                style: {
                    'background-color': "#43B581",
                    'border': "2px solid #F04747"
                }
            },
            waiting: {
                text: "В_ожидании",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            onAdjust: {
                text: "На_согласовании",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            adjusted: {
                text: "Согласовано",
                style: {
                    'background-color': "#43B581"
                }
            },
            notAdjusted: {
                text: "Не_согласовано",
                style: {
                    'background-color': "#F04747"
                }
            },
            rework: {
                text: "Возвращено_на_доработку",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            needRework: {
                text: "Доработать",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            onRework: {
                text: "На_доработке",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            cancelled: {
                text: "Отменено",
                style: {
                    'background-color': "#9e9e9e"
                }
            },
            none: {
                text: "Не_определено",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            onSign: {
                text: "На_подписи",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            signed: {
                text: "Подписано",
                style: {
                    'background-color': "#43B581"
                }
            },
            signReject: {
                text: "Отказано_в_подписи",
                style: {
                    'background-color': "#F04747"
                }
            },
            hasAnswer: {
                text: "Дан_ответ",
                style: {
                    'background-color': "#43B581"
                }
            },
            answerSended: {
                text: "Ответ_отправлен",
                style: {
                    'background-color': "#43B581"
                }
            },
            new: {
                text: "Новое",
                style: {
                    'background-color': "#00AEEF"
                }
            },
            onRegistration: {
                text: "На_регистрации",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            rejected: {
                text: "Отклонен",
                style: {
                    'background-color': "#F04747"
                }
            },
            refusal: {
                text: "Отказ",
                style: {
                    'background-color': "#F04747"
                }
            },
            registered: {
                text: "Зарегистрирован",
                style: {
                    'background-color': "#43B581"
                }
            },
            sended: {
                text: "Отправлен",
                style: {
                    'background-color': "#43B581"
                }
            },
            onExecution: {
                text: "На_исполнении",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            onExecutionCheck: {
                text: "На_проверке_исполнения",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            executed: {
                text: "Исполнено",
                style: {
                    'background-color': "#43B581"
                }
            },
            executeExpired: {
                text: "Исполнено_не_в_срок",
                style: {
                    'background-color': "#F04747"
                }
            },
            completed: {
                text: "Завершен",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            deleted: {
                text: "Удален",
                style: {
                    'background-color': "#F04747"
                }
            },
            control: {
                text: "Контроль",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            specialControl: {
                text: "Особый_контроль",
                style: {
                    'background-color': "#f57731"
                }
            },
            takeOffControl: {
                text: "Снят_с_контроля",
                style: {
                    'background-color': "#9B84EE"
                }
            },
            redirected: {
                text: "Перепоручено",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            redirectedControl: {
                text: "На_контроле",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            closed: {
                text: "Закрыто",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            expiresin1day: {
                text: "Срок_через_1_день",
                style: {
                    'background': '#e96262'
                }
            },
            expiresin2days: {
                text: "Срок_через_2_дня",
                style: {
                    'background': '#f57731'
                }
            },
            expiresin3days: {
                text: "Срок_через_3_дня",
                style: {
                    'background': '#f1884f'
                }
            },
            unknown: {
                text: "Неизвестно",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            euolSended: {
                text: "Отправлено_в_ШЭП",
                style: {
                    'background-color': "#43B581"
                }
            },
            euolWaitSend: {
                text: "Ожидает_отправки_в_ШЭП",
                style: {
                    'background-color': "#FAA61A"
                }
            },

            //#region
            acepted: {
                text: "Принят",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            waitPayment: {
                text: "Ожидание_оплаты",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            paymentMade: {
                text: "Оплата_произведена",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            onSignatureOfApplicant: {
                text: "На_подписании_у_заявителя",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            onSignatureOfServiceProvider: {
                text: "На_подписании_у_услугодателя",
                style: {
                    'background-color': "#FAA61A"
                }
            },
            //#endregion
            //#region ResolutionSendStatus
            rssOnSign: {
                text: "На_подписании",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            rssSignedByChief: {
                text: "Подписано",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            rssSended: {
                text: "Отправлено",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
            //#endregion
            //#region svetofor
            more10days: {
                text: "Срок_более_10_дней",
                style: {
                    'background-color': "#43B581"
                }
            },
            less10days: {
                text: "Срок_менее_10_дней",
                style: {
                    'background-color': "#f1884f"
                }
            },
            overdue: {
                text: "Просрочено",
                style: {
                    'background-color': "#F04747"
                }
            },
            //#endregion
            /*outgoing sendstatuses*/
            outgoingNotSended: {
                text: "Не_отправлен",
                style: {
                    'background-color': "#F04747"
                },
                icon: "fas fa-exclamation-circle"
            },
            outgoingSended: {
                text: "Отправлен",
                style: {
                    'background-color': "#43B581"
                },
                icon: "fas fa-angle-double-up"
            },
            outgoingSendedButRejected: {
                text: "Отклонен",
                style: {
                    'background-color': "#F04747"
                },
                icon: "fas fa-angle-double-down"
            }
        },
        LinkedTypes: {
            0: 'Связанные_документы',
            1: 'Является_ответным_на',
            2: 'Имеет_ссылку_с',
            3: 'Является_дубликатом',
            4: 'Является_повтором',
            5: 'Имеет_дубликаты',
            6: 'Имеет_повторы',
            7: 'Заявка_с_договором',
            8: 'Договор_к_заявке',
            9: 'Ответы_на_пункты',
        },
        SendStatuses:
        [
            { id: 0, Value: "Не_отправлен" },
            { id: 1, Value: "Отправлен" },
            { id: 2, Value: "Отклонен" }
        ],
        PeriodControlTypes: [
            { id: 1, Value: "Ежедневный" },
            { id: 2, Value: "Еженедельный" },
            { id: 3, Value: "Ежемесячный" },
            { id: 4, Value: "Ежеквартальный" },
            { id: 5, Value: "Ежегодный" },
            { id: 6, Value: "Произвольный" },
        ],
        WorkWeekDays: [
            { id: 1, Value: "Понедельник" }, 
            { id: 2, Value: "Вторник" },
            { id: 3, Value: "Среда" },
            { id: 4, Value: "Четверг" },
            { id: 5, Value: "Пятница" },
         ],
        NPATypes: [
            { id: 0, Value: 'Решение'},
            { id: 1, Value: 'Постановление'},
            { id: 2, Value: 'Распоряжение'},
        ]
    }),
    mutations:
    {
        PUSH_REFERENCE (state, payload)
        {
            state.references.push(payload);
        },
        UPDATE_REFERENCE (state, payload)
        {
            state.references[payload.index] = payload.value;
        },
        SET_WORKPLACES (state, payload)
        {
            state.workplaces = payload;
        },
        SET_WORKPLACES_SYNC (state, payload)
        {
            state.workplacesSyncAt = payload;
        },
        SET_SIGNERS (state, payload)
        {
            state.signers = payload;
        },
        SET_SIGNERS_SYNC (state, payload)
        {
            state.signersSyncAt = payload;
        },
        SET_DEPARTMENT_NOMENCLATURES (state, payload)
        {
            state.departmentNomenclatures = payload;
        },
        SET_DEPARTMENT_NOMENCLATURES_SYNC (state, payload)
        {
            state.departmentNomenclaturesSyncAt = payload;
        },
        SET_ENTERPRISE_NOMENCLATURES (state, payload)
        {
            state.enterpriseNomenclatures = payload;
        },
        SET_ENTERPRISE_NOMENCLATURES_SYNC (state, payload)
        {
            state.enterpriseNomenclaturesSyncAt = payload;
        },
        SET_ALL_NOMENCLATURES (state, payload)
        {
            state.allNomenclatures = payload;
        },
        SET_ALL_NOMENCLATURES_SYNC (state, payload)
        {
            state.allNomenclaturesSyncAt = payload;
        },
        SET_CONTRACTORS (state, payload)
        {
            state.contractors = payload;
        },
        SET_CONTRACTORS_SYNC (state, payload)
        {
            state.contractorsSyncAt = payload;
        },
        SET_OUTER_PERSONS (state, payload)
        {
            state.outerPersons = payload;
        },
        SET_OUTER_PERSONS_SYNC (state, payload)
        {
            state.outerPersonsSyncAt = payload;
        },
        SET_OUTER_JURIDICAL (state, payload)
        {
            state.outerJuridical = payload;
        },
        SET_OUTER_JURIDICAL_SYNC (state, payload)
        {
            state.outerJuridicalSyncAt = payload;
        },
        SET_EXECUTER_GROUPS (state, payload)
        {
            state.executerGroups = payload;
        },
        SET_EXECUTER_GROUPS_SYNC (state, payload)
        {
            state.executerGroupsSyncAt = payload;
        },
        PUSH_EXECUTER_GROUP_EXECUTERS (state, payload)
        {
            state.executerGroupExecuters.push(payload);
        },
        UPDATE_EXECUTER_GROUP_EXECUTERS (state, payload)
        {
            state.executerGroupExecuters[payload.index] = payload.value;
        },
        SET_INNER_ACT_ITEMS_ENTERPRISE_EXECUTERS (state, payload)
        {
            state.innerActItemsEnterpriseExecuters = payload;
        },
        SET_INNER_ACT_ITEMS_ENTERPRISE_EXECUTERS_SYNC (state, payload)
        {
            state.innerActItemsEnterpriseExecutersSyncAt = payload;
        },
        SET_EXECUTERS_FOR_MEMBER_DLG (state, payload){
            state.executersForMemberDlg = payload;
        },
        SET_EXECUTERS_FOR_MEMBER_DLG_SYNC (state, payload){
            state.executersForMemberDlgSyncAt = payload;
        },

        SET_NEW_DIALOG_INNEREXECUTERS (state, payload){
            state.newDialogInnerExecuters=payload
        },
        SET_NEW_DIALOG_INNEREXECUTERS_SYNC (state, payload){
            state.newDialogInnerExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_APPLYRESOLUTIONS (state, payload){
            state.newDialogApplyResolutions=payload
        },
        SET_NEW_DIALOG_APPLYRESOLUTIONS_SYNC (state, payload){
            state.newDialogApplyResolutionsSyncAt=payload
        },

        SET_NEW_DIALOG_SIGNERS (state, payload){
            state.newDialogSigners=payload
        },
        SET_NEW_DIALOG_SIGNERS_SYNC (state, payload){
            state.newDialogSignersSyncAt=payload
        },
        SET_NEW_DIALOG_OUTER_SIGNERS (state, payload){
            state.newDialogOuterSigners=payload
        },
        SET_NEW_DIALOG_OUTER_SIGNERS_SYNC (state, payload){
            state.newDialogOuterSignersSyncAt=payload
        },
        SET_NEW_DIALOG_OUTEREXECUTERS (state, payload){
            state.newDialogOuterExecuters=payload
        },
        SET_NEW_DIALOG_OUTEREXECUTERS_SYNC (state, payload){
            state.newDialogOuterExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_ESEDOEXECUTERS (state, payload){
            state.newDialogESEDOExecuters=payload
        },
        SET_NEW_DIALOG_ESEDOEXECUTERS_SYNC (state, payload){
            state.newDialogESEDOExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_JUREXECUTERS (state, payload){
            state.newDialogJurExecuters=payload
        },
        SET_NEW_DIALOG_JUREXECUTERS_SYNC (state, payload){
            state.newDialogJurExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_FIZEXECUTERS (state, payload){
            state.newDialogFizExecuters=payload
        },
        SET_NEW_DIALOG_FIZEXECUTERS_SYNC (state, payload){
            state.newDialogFizExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_GROUPEXECUTERS (state, payload){
            state.newDialogGroupExecuters=payload
        },
        SET_NEW_DIALOG_GROUPEXECUTERS_SYNC (state, payload){
            state.newDialogGroupExecutersSyncAt=payload
        },
        SET_ENTERPRISES (state, payload){
            state.enterprises = payload;
        },
        SET_ENTERPRISES_SYNC (state, payload){
            state.enterprisesSyncAt=payload
        },
    },
    actions:
    {
        async getReference({ commit, state }, { id }) {
            if (id === null || id === undefined)
                throw { message: "Значение id не может быть неопределенным" };

            let localRef = state.references.find(x => x.id == id);

            var now = this._vm.$moment(Date.now());

            if (localRef == null)
            {
                let response = await httpAPI({
                    url: `api/references/reference?type=${id}`,
                    method: 'GET'
                });

                if (response) {
                    localRef = { id, value: response.data.payload, syncAt: Date.now() };
                    commit('PUSH_REFERENCE', localRef);
                }
            }
            else if (this._vm.$moment.duration(now.diff(this._vm.$moment(localRef.syncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let index = state.references.indexOf(localRef);

                let response = await httpAPI({
                    url: `api/references/reference?type=${id}`,
                    method: 'GET'
                });

                if (response) {
                    localRef = { id, value: response.data.payload, syncAt: Date.now() };
                    commit({ type: 'UPDATE_REFERENCE', index, value: localRef });
                }
            }
            return localRef.value;
        },
        async getWorkplaces({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.workplacesSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/workplaces`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_WORKPLACES', response.data.payload);
                    commit('SET_WORKPLACES_SYNC', Date.now());
                }
            }

            return state.workplaces;
        },
        async getExecuterGroups({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.executerGroupsSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/executergroups`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_EXECUTER_GROUPS', response.data.payload.data);
                    commit('SET_EXECUTER_GROUPS_SYNC', Date.now());
                }
            }

            return state.executerGroups;
        },
        async getExecutersFromGroup({ state, commit }, { id }) {
            if (id === null || id === undefined)
                throw { message: "Значение id не может быть неопределенным" };

            let localGroupExecuters = state.executerGroupExecuters.find(x => x.id == id);

            var now = this._vm.$moment(Date.now());

            if (localGroupExecuters == null)
            {
                let response = await httpAPI({
                    url: `api/references/executers/${id}`,
                    method: 'GET'
                });

                if (response) {
                    localGroupExecuters = { id, value: response.data.payload, syncAt: Date.now() };
                    commit('PUSH_EXECUTER_GROUP_EXECUTERS', localGroupExecuters);
                }
            }
            else if (this._vm.$moment.duration(now.diff(this._vm.$moment(localGroupExecuters.syncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let index = state.executerGroupExecuters.indexOf(localGroupExecuters);

                let response = await httpAPI({
                    url: `api/references/executers/${id}`,
                    method: 'GET'
                });

                if (response) {
                    localGroupExecuters = { id, value: response.data.payload, syncAt: Date.now() };
                    commit({ type: 'UPDATE_EXECUTER_GROUP_EXECUTERS', index, value: localGroupExecuters });
                }
            }

            return localGroupExecuters.value;
        },
        async getSigners({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.signersSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/signers`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_SIGNERS', response.data.payload);
                    commit('SET_SIGNERS_SYNC', Date.now());
                }
            }

            return state.signers;
        },
        async getNomenclatures({ commit, state }, { forDepartment }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(forDepartment ? state.departmentNomenclaturesSyncAt : state.enterpriseNomenclaturesSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/nomenclatures?forDepartment=${forDepartment}`,
                    method: 'GET'
                });
                
                if (response) {

                    if (forDepartment)
                    {
                        commit('SET_DEPARTMENT_NOMENCLATURES', response.data.payload);
                        commit('SET_DEPARTMENT_NOMENCLATURES_SYNC', Date.now());
                    }
                    else
                    {
                        commit('SET_ENTERPRISE_NOMENCLATURES', response.data.payload);
                        commit('SET_ENTERPRISE_NOMENCLATURES_SYNC', Date.now());
                    }
                }
            }

            return forDepartment ? state.departmentNomenclatures : state.enterpriseNomenclatures;
        },
        async getContractors({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.contractorsSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/contractors?contractorType=0&onlyEsedo=true`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_CONTRACTORS', response.data.payload);
                    commit('SET_CONTRACTORS_SYNC', Date.now());
                }
            }

            return state.contractors;
        },
        async getOuterPersons({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.outerPersonsSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/contractors?contractorType=1&onlyEsedo=false`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_OUTER_PERSONS', response.data.payload);
                    commit('SET_OUTER_PERSONS_SYNC', Date.now());
                }
            }

            return state.outerPersons;
        },
        async getOuterJuridical({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.outerJuridicalSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/contractors?contractorType=0&onlyEsedo=false`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_OUTER_JURIDICAL', response.data.payload);
                    commit('SET_OUTER_JURIDICAL_SYNC', Date.now());
                }
            }

            return state.outerJuridical;
        },
        async getTimestamp() {
            let time = null;

            let response = await httpAPI({
                url: `api/references/timestamp`,
                method: 'GET'
            });

            if (response)
                time = response.data.payload;

            return time;
        },
        async getInnerActItemsEnterpriseExecuters({ commit, state }) {
            
            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.innerActItemsEnterpriseExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/inneractitemsenterpriseexecuters`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_INNER_ACT_ITEMS_ENTERPRISE_EXECUTERS', response.data.payload);
                    commit('SET_INNER_ACT_ITEMS_ENTERPRISE_EXECUTERS_SYNC', Date.now());
                }
            }

            return state.innerActItemsEnterpriseExecuters;
        },
        async getExecutersForMemberDlg({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.executersForMemberDlgSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let responce = await httpAPI({
                    url: `api/ems/getexecutors`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({
                        inner:true,
                        outer:true,
                        esedo:true,
                        jur:true,
                        //fiz:true,
                        group:true,
                        //search: state.search
                    }) },
                });
                
                if (responce) {
                    commit('SET_EXECUTERS_FOR_MEMBER_DLG', responce.data.payload);
                    commit('SET_EXECUTERS_FOR_MEMBER_DLG_SYNC', Date.now());
                }
            }

            return state.executersForMemberDlg;
        },
        async getInnerExecutersForNewMemberDlg({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.executersForMemberDlgSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let responce = await httpAPI({
                    url: `api/ems/getexecutors`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({
                        inner:true,
                        outer:true,
                        esedo:true,
                        jur:true,
                        //fiz:true,
                        group:true,
                        //search: state.search
                    }) },
                });
                
                if (responce) {
                    commit('SET_EXECUTERS_FOR_MEMBER_DLG', responce.data.payload);
                    commit('SET_EXECUTERS_FOR_MEMBER_DLG_SYNC', Date.now());
                }
            }

            return state.executersForMemberDlg;
        },
        async getNewDialogExecuters({commit, state}, { params }){
            let ret = [];
            let query_param = {};
            var now = this._vm.$moment(Date.now());
            if (params.applyresolutions){
                let applyResolutionsCached = true;
                if (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogApplyResolutionsSyncAt))).asMinutes() > state.сacheTimeInMinutes) {

                    let responce = await httpAPI({
                        url: `api/ems/executerslist?filter=${JSON.stringify({ applyresolutions: true })}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                    if (responce) {
                        commit('SET_NEW_DIALOG_APPLYRESOLUTIONS', responce.data.payload.InnerExecuters);
                        commit('SET_NEW_DIALOG_APPLYRESOLUTIONS_SYNC', Date.now());
                        
                        applyResolutionsCached = false;
                    }
                }

                ret.ApplyResolutions = { cached: applyResolutionsCached, values: state.newDialogApplyResolutions };
            }
            else if (params.signersonly) {
                let signersCached = true;
                let outerSignersCached = true;
                let include_outer = params.include_outer && (params.ignore_cache || params.signers_boundaries != null || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogOuterSignersSyncAt))).asMinutes() > state.сacheTimeInMinutes);
                let tmpOuterExecuters = null;

                if (params.ignore_cache || include_outer || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogSignersSyncAt))).asMinutes() > state.сacheTimeInMinutes) {

                    query_param.signersonly = true;
                    query_param.include_outer = include_outer;
                    query_param.signersenterprises = params.signers_boundaries;
                    //query_param = { signersonly: true, include_outer, signersenterprises: params.signers_boundaries };
                    let responce = await httpAPI({
                        url: `api/ems/executerslist?filter=${JSON.stringify(query_param)}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                    if (responce) {
                        commit('SET_NEW_DIALOG_SIGNERS', responce.data.payload.InnerExecuters);
                        commit('SET_NEW_DIALOG_SIGNERS_SYNC', Date.now());
                        
                        if (include_outer) {
                            if (params.signers_boundaries == null) {
                                commit('SET_NEW_DIALOG_OUTER_SIGNERS', responce.data.payload.OuterExecuters);
                                commit('SET_NEW_DIALOG_OUTER_SIGNERS_SYNC', Date.now());
                                outerSignersCached = false;
                            } else {                                
                                tmpOuterExecuters = responce.data.payload.OuterExecuters;
                                outerSignersCached = false;
                            }
                        }

                        signersCached = false;
                    }
                }

                ret.Signers = { cached: signersCached, values: state.newDialogSigners };

                if (params.include_outer)
                    ret.OuterSigners = { cached: outerSignersCached, values: params.signers_boundaries == null ? state.newDialogOuterSigners : tmpOuterExecuters };
            }
            else {
                if (params.include_inner && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogInnerExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))
                    query_param.include_inner = true;

                if (params.include_outer && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogOuterExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))    
                    query_param.include_outer = true;

                if (params.include_esedo && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogESEDOExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes)) {
                    query_param.include_esedo = true;
                    
                    if (params.include_work_enterprises)
                        query_param.include_work_enterprises = true;
                }

                if (params.include_jur && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogJurExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))
                    query_param.include_jur = true;

                if (params.include_fiz && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogFizExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))   
                    query_param.include_fiz = true;

                if (params.include_groups && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogGroupExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))    
                    query_param.include_groups = true;

                if (query_param.include_inner || query_param.include_outer || query_param.include_esedo || query_param.include_jur || query_param.include_fiz || query_param.include_groups) {

                    let responce = await httpAPI({
                        url: `api/ems/executerslist?filter=${JSON.stringify(query_param)}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                    if (responce) {

                        if (responce.data.payload.InnerExecuters) {
                            commit('SET_NEW_DIALOG_INNEREXECUTERS', responce.data.payload.InnerExecuters);
                            commit('SET_NEW_DIALOG_INNEREXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_inner = false;

                        if (responce.data.payload.OuterExecuters) {
                            commit('SET_NEW_DIALOG_OUTEREXECUTERS', responce.data.payload.OuterExecuters);
                            commit('SET_NEW_DIALOG_OUTEREXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_outer = false;

                        if (responce.data.payload.OuterESEDO) {
                            commit('SET_NEW_DIALOG_ESEDOEXECUTERS', responce.data.payload.OuterESEDO);
                            commit('SET_NEW_DIALOG_ESEDOEXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_esedo = false;

                        if (responce.data.payload.OuterJur) {
                            commit('SET_NEW_DIALOG_JUREXECUTERS', responce.data.payload.OuterJur);
                            commit('SET_NEW_DIALOG_JUREXECUTERS_SYNC', Date.now());
                        }
                        else
                            query_param.include_jur = false;

                        if (responce.data.payload.OuterFiz) {
                            commit('SET_NEW_DIALOG_FIZEXECUTERS', responce.data.payload.OuterFiz);
                            commit('SET_NEW_DIALOG_FIZEXECUTERS_SYNC', Date.now());
                        }
                        else
                            query_param.include_fiz = false;
                        
                        if (responce.data.payload.ExecuterGroups) {
                            commit('SET_NEW_DIALOG_GROUPEXECUTERS', responce.data.payload.ExecuterGroups);
                            commit('SET_NEW_DIALOG_GROUPEXECUTERS_SYNC', Date.now());
                        }
                        else
                            query_param.include_groups = false;
                    }
                }

                if (params.include_inner)
                    ret.InnerExecuters = { cached: !query_param.include_inner, values: state.newDialogInnerExecuters};

                if (params.include_outer)
                    ret.OuterExecuters = { cached: !query_param.include_outer, values: state.newDialogOuterExecuters};

                if (params.include_esedo)
                    ret.OuterESEDO = { cached: !query_param.include_esedo, values: state.newDialogESEDOExecuters};

                if (params.include_jur)
                    ret.OuterJur = { cached: !query_param.include_jur, values: state.newDialogJurExecuters};

                if (params.include_fiz)
                    ret.OuterFiz = { cached: !query_param.include_fiz, values: state.newDialogFizExecuters};

                if (params.include_groups)
                    ret.ExecuterGroups = { cached: !query_param.include_groups, values: state.newDialogGroupExecuters};
            }

            return ret;
        },
        async getDialogExecutersForNPA({ rootGetters, dispatch }, {params}){
            let ret = {};
            let query_param = {};
            let npaDocument = rootGetters['actionsource/getDataSource']?.Data.Object.Document;
            if (npaDocument){
                let signingEntId = npaDocument.Card.EnterpriseAcceptingActId;
                if (signingEntId){
                    if (params == 'SignersAct'){
                        query_param = {
                            include_inner: true,
                            entid: signingEntId,
                            signersonly: true
                        }
                        let responce = await httpAPI({
                            url: `api/ems/executerslist?filter=${JSON.stringify(query_param)}`,
                            method: 'GET',
                            headers: { 'Content-Type': 'application/json' },
                        });
                        if (responce){
                            if (responce.data.payload.InnerExecuters) {
                                ret.Signers = { cached: false, values: responce.data.payload.InnerExecuters};
                            }
                        }
                    }
                    else if (params == 'Adjasments'){
                        ret = await dispatch('getNewDialogExecuters', {params: {include_inner:true, include_outer: true }});
                        let userInfo = rootGetters['auth/getUserInfo'];
                        if (userInfo.enterpriseId != signingEntId){
                            query_param = {
                                include_inner: true,
                                entid: signingEntId,
                            }
                            let responce = await httpAPI({
                                url: `api/ems/executerslist?filter=${JSON.stringify(query_param)}`,
                                method: 'GET',
                                headers: { 'Content-Type': 'application/json' },
                            });
                            if (responce){
                                if (responce.data.payload.InnerExecuters) {
                                    ret.OuterExecuters.cached = false;
                                    ret.OuterExecuters.values = ret.OuterExecuters.values.filter(item => item.element_type == 'Enterprise' || item.enterpriseid != signingEntId);
                                    if (!(ret.OuterExecuters.values.find(item => item.element_type == 'Enterprise' && item.id==signingEntId))){
                                        ret.OuterExecuters.values.push({
                                            "id": signingEntId,
                                            "parent_id": null,
                                            "element_category": "OuterExecuter",
                                            "element_type": "Enterprise",
                                            "name":  i18n.locale=='kz' ? npaDocument.Card.EnterpriseAcceptingActName["kk-KZ"] : npaDocument.Card.EnterpriseAcceptingActName["ru-RU"],
                                            "allow": false,
                                            "level": 0
                                        });
                                    }

                                    responce.data.payload.InnerExecuters.forEach(item => {
                                        item.element_category = 'OuterExecuter';
                                        item.level = item.level + 1;
                                        if (!item.parent_id)
                                            item.parent_id = signingEntId
                                        ret.OuterExecuters.values.push(item);
                                    });
                                }
                            }
                        }
                    }
                }
            }
            return ret;
        },
        async getEnterprises({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.enterprisesSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/references/enterprises`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_ENTERPRISES', response.data.payload.data);
                }
            }

            return state.enterprises;
        },
    },
    getters:
    {
        GetReferences: (s) => s.references,
        GetReference: (s) => (refId) => s.references.find(ref => ref.id === refId)?.value ?? [],
        GetWorkplaces: (s) => s.workplaces,
        GetSigners: (s) => s.signers,

        GetContractors: (s) => s.contractors,
        GetOuterPersons: (s) => s.outerPersons,
        GetOuterJuridical: (s) => s.outerJuridical,        
        
        GetDeclarantCategoryTypes: (s) => s.DeclarantCategoryTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),        
        GetIQalaDeclarantCategoryTypes: (s) => s.IQalaDeclarantCategoryTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetIQala0103X017EnergySupplyOrgsList: (s) => s.IQala0103X017EnergySupplyOrgsList.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetAdjustmentTypes: (s) => s.AdjustmentTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetIncomingLinksTypes: (s) => s.IncomingLinksTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetInnerLinksTypes: (s) => s.InnerLinksTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetOutcomingLinksTypes: (s) => s.OutcomingLinksTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        
        GetDocumentTypes: (s) => s.DocumentTypes,
        GetControlTypes: (s) => s.ControlTypes,

        GetEDSTypes: (s) => s.EDSTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),
        GetEDSStatusesTypes: (s) => s.EDSStatusesTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),        

        GetAvaibleStatuses: (s) => s.avaibleStatuses,

        GetLinkedTypes: (s) => s.LinkedTypes,

        GetSendStatuses: (s) => s.SendStatuses.map(x => ({id: x.id, Value: i18n.t(x.Value)})),

        GetPeriodControlTypes: (s) => s.PeriodControlTypes.map(x => ({id: x.id, Value: i18n.t(x.Value)})),

        GetWorkWeekDays: (s) => s.WorkWeekDays.map(x => ({id: x.id, Value: i18n.t(x.Value)})),

        GetNPATypes: (s) => s.NPATypes,
    }
}