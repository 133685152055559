import MyControlTask from './lists/MyControlTask'
import MyCreatedTask from './lists/MyCreatedTask'
import MyExecutionTask from './lists/MyExecutionTask'

export default {
    namespaced: true,
    state: () => (
    {
        collection : "MyExecutionTask",
        statuses:
        [
            {
                collection: "MyExecutionTask",
                value: "-1"
            },
            {
                collection: "MyCreatedTask",
                value: "-1"
            },
            {
                collection: "MyControlTask",
                value: "-1"
            }
        ],
        searches: [],
        extendedFilters: [
            {
                collection: "MyExecutionTask",
                value: {
                    OrderTypeof: {
                        label: "Тип",
                        value: null
                    },
                    IsSignerControl: {
                        label: "Личный_контроль",
                        value: null
                    }
                }
            },
            {
                collection: "MyCreatedTask",
                value: {
                    OrderTypeof: {
                        label: "Тип",
                        value: null
                    },
                    IsSignerControl: {
                        label: "Личный_контроль",
                        value: null
                    }
                }
            },
            {
                collection: "MyControlTask",
                value: {
                    OrderTypeof: {
                        label: "Тип",
                        value: null
                    },
                    MyControlExecuterID: {
                        label: "Исполнитель",
                        value: null
                    }
                }
            }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let searchExists = state.statuses.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        },
        PUSH_EXTENDED_FILTER (state, payload) {
            let exists = state.extendedFilters.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            } else {
                state.extendedFilters.push(payload);
            }
        }
    },
    actions: {
        getExtendedFilterByCollection ({ state }, collection) {
            return state.extendedFilters.find(x => x.collection === collection).value;
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses;
        },
        getSearches (state) {
            return state.searches;
        },
        getExtendedFilter (state) {
            return state.extendedFilters.find(x => x.collection === state.collection).value;
        }
    },
    modules: {
        mycontrol: MyControlTask,
        mycreated: MyCreatedTask,
        myexecution: MyExecutionTask
    }
}