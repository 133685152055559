const certificateExpires = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        certificateEndDate: null,
        certificateDaysRemain: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_CERTIFICATE_END_DATE(state, payload) {
            state.certificateEndDate = payload;
        },
        SET_CERTIFICATE_DAYS_REMAIN(state, payload) {
            state.certificateDaysRemain = payload;
        },
    },
    actions: {
        async open({ commit }, { certEndDate, certDaysRemain }) {
            commit('SET_CERTIFICATE_END_DATE', certEndDate);
            commit('SET_CERTIFICATE_DAYS_REMAIN', certDaysRemain);
            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve();
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        getCertificateEndDate: (s) => s.certificateEndDate,
        getCertificateDaysRemain: (s) => s.certificateDaysRemain
    }
}

export default certificateExpires;