const initNewEECBActMetadata = function() {
    return {
        __type: "EECBActMetadata:#Avrora.Objects.Modules.Docflow.DocflowObjects",
        // Предмет регулирования НПА
        Title: {
            "ru-RU": null,
            "kk-KZ": null,
        },
        // Регион действия
        Region: {
            id: null,
            Value: null
        },
        // Идентификатор органа-разработчика
        DeveloperEnterpriseId: null,
        // Место принятия
        ApprovalPlace: {
            id: null,
            Value: null
        },
        // Дата гос. регистрации
        JudiciaryDate: null,
        // Номер гос. регистрации
        JudiciaryNumber: null,
        // Id органа гос. регистрации
        JudiciaryAuthorityId: '00000000-0000-0000-0000-000000000000',
        // Данные о принятии акта
        ApprovalDataList: [],
        // Информация о публикации акта
        PublicationList: []
    }
};

const govermentRegistration = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        activeTab: null,
        dateMenu: false,

        resolve: null,
        reject: null,

        eecbMetaData: null,
        isNPA: false,

        regions: [],
        publishSources: []
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_ACTIVE_TAB(state, payload) {
            state.activeTab = payload;
        },
        SET_DATE_MENU(state, payload) {
            state.dateMenu = payload;
        },
        SET_EECB_META_DATA(state, payload) {
            state.eecbMetaData = payload;
        },
        UPDATE_EECB_META_DATA(state, payload) {
            this._vm.$_.set(state.eecbMetaData, payload.property, payload.value)
        },
        SET_REGIONS(state, payload) {
            state.regions = payload;
        },
        SET_PUBLISH_SOURCE(state, payload) {
            state.publishSources = payload;
        },
        SET_IS_NPA(state, payload){
            state.isNPA = payload;
        }
    },
    actions: {
        async open({ commit, dispatch, rootGetters }, eecbMetaData) {
            let isNPA = rootGetters['actionsource/getDataSourceType'] === 'NPADocument';
            commit('SET_IS_NPA', isNPA);

            if (!eecbMetaData) {
                eecbMetaData = initNewEECBActMetadata();

                if (isNPA) {
                    let document = rootGetters['actionsource/getDataSource']?.Data.Object.Document;
                    eecbMetaData.DeveloperEnterpriseId = document.EnterpriseId;

                    eecbMetaData.ApprovalDataList.push({
                        __type: "EECBApprovalData:#Avrora.Objects.Modules.Docflow.DocflowObjects",
                        // Идентификатор органа, принявшего акт
                        EnterpriseId: document.Card.EnterpriseAcceptingActId,
                        // Номер регистрации
                        Number: document.Card.RegNumber,
                        // Дата регистрации
                        Date: document.Card.RegDate
                    });
                }
            }

            commit('SET_EECB_META_DATA', eecbMetaData);            
            
            commit('SET_ACTIVE_TAB', null);
            commit('SET_DATE_MENU', false);

            commit('SET_LOADING', true);

            commit('SET_VISIBLE', true);
            let regions = Array.from(await dispatch('references/getReference', { id: 1032 }, { root: true }));
            let publishSources = Array.from(await dispatch('references/getReference', { id: 1033 }, { root: true }));
            commit('SET_REGIONS', regions);
            commit('SET_PUBLISH_SOURCE', publishSources);

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.eecbMetaData);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: `Действие_отменено` });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getEECBMetaData: (s) => s.eecbMetaData,
        isDateMenuOpen: (s) => s.dateMenu,
        getActiveTab: (s) => s.activeTab,
        getRegions: (s) => s.regions,
        getPublishSources: (s) => s.publishSources,
        isNPA: (s) => s.isNPA
    }
}

export default govermentRegistration;