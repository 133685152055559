import { httpAPI } from "@/api/httpAPI";

const startProcess = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,
        selectedRoute: null,
        routesData: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_SELECTED_ROUTE(state, payload) {
            state.selectedRoute = payload;
        },
        SET_ROUTES_DATA(state, payload) {
            state.routesData = payload;
        }
    },
    actions: {
        async open({ commit }, type) {
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            let response = await httpAPI({
                url: `/api/references/workflowschemas?type=${type}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            commit('SET_LOADING', false);

            if (response)
                commit('SET_ROUTES_DATA', response.data.payload);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.selectedRoute);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getSelectedRoute: (s) => s.selectedRoute,
        getRoutes: (s) =>  s.routesData.Data.Object?.Data?.map(x => ({ id: x[0], name: x[1], author: x[2], version: x[3], created: x[4] })) ?? [],
    }
}

export default startProcess;