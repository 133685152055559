import sys from '@/services/system';
import i18n from '@/i18n'

const cscAnswer = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        decision: null,
        decisions: [{ id: 1, Value: 'Положительный_ответ' }, { id: 2, Value: 'Мотивированный_отказ' }],
        reason: null,
        reasons: [],

        answerTextKz: null,
        answerTextRu: null,
        
        attachments: [],
    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DECISION(state, payload) {
            state.decision = payload;
        },
        SET_DECISIONS(state, payload) {
            state.decisions = payload;
        },
        SET_REASON(state, payload) {
            state.reason = payload;
        },
        SET_REASONS(state, payload) {
            state.reasons = payload;
        },
        SET_ANSWER_TEXT_KZ(state, payload) {
            state.answerTextKz = payload;
        },
        SET_ANSWER_TEXT_RU(state, payload) {
            state.answerTextRu = payload;
        },
        SET_ATTACHMENTS(state, payload){
            state.attachments = payload;
        },
        PUSH_ATTACHMENT(state, payload){
            payload.descriptionKz = payload.descriptionRu =  payload.name;
            let attachments = state.attachments.map(a => a);
            attachments.push(payload);
            state.attachments = attachments;
        },
        DEL_ATTACHMENT(state, payload) {
            if (payload > -1)
                state.attachments.splice(payload, 1);
        }
    },
    actions: {
        async open({ commit, dispatch, getters }) {

            commit('SET_DECISION', getters.decisions[0]);

            commit('SET_IS_PENDING', true);
            commit('SET_IS_VISIBLE', true);
            
            //Initialize
            commit('SET_REASON', null);
            commit('SET_ANSWER_TEXT_KZ', null);
            commit('SET_ANSWER_TEXT_RU', null);
            commit('SET_ATTACHMENTS', []);

            let reasons = await dispatch('references/getReference', { id: 5300 }, { root: true });

            commit('SET_REASONS', reasons);
            commit('SET_IS_PENDING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, rootGetters }) {
            commit('SET_IS_VISIBLE', false);

            let result =  {
                DocumentId: rootGetters['actionsource/getDataSource'].Data.Object.Document.id,
                Decision: state.decision.id,
                ReasonId: state.decision.id == 2 ? state.reason.id : -1,
                AnswerTextKz: state.answerTextKz,
                AnswerTextRu: state.answerTextRu,

                Attachments: state.attachments.length > 0 
                    ? state.attachments.map((attachment) => {
                            return {                    
                                LastModified: attachment.lastModified,
                                LastModifiedDate: `/Date(${attachment.lastModified})/`,
                                Name: attachment.name,
                                Size: attachment.size,
                                Type: attachment.type,
                                DescriptionKz: attachment.descriptionKz,
                                DescriptionRu: attachment.descriptionRu,
                            }
                    })   
                    : []  
            };
            
            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        setDecision({ commit }, v) {
            commit('SET_DECISION', v);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,
        
        isValid: (s) => (s.decision.id == 1 || (s.decision.id == 2 && s.reason != null)) 
            && (s.attachments.length == 0 || (s.attachments.length > 0 && sys.isNotEmpty(...s.attachments.reduce((acc, val) => { acc.push(val.descriptionKz); acc.push(val.descriptionRu); return acc; }, [])))) 
            && (sys.isEmpty(s.answerTextKz, s.answerTextRu) || sys.isNotEmpty(s.answerTextKz, s.answerTextRu)),
        
        decision: (s) => s.decision,
        decisions: (s) => s.decisions.map(x => ({ id: x.id, Value: i18n.t(x.Value) })),
        reason: (s) => s.reason,
        reasons: (s) => s.reasons,        
        attachments: (s) => s.attachments,
        answerTextKz: (s) => s.answerTextKz,
        answerTextRu: (s) => s.answerTextRu,

    }
}

export default cscAnswer;