import { httpAPI } from "@/api/httpAPI";

const cscRegistry = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,
        
        reserveItem: null,
        counterType: 1,
        registerParam: {},
        isFormValid: false,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_RESERVE_ITEM(state, payload) {
            state.reserverItem = payload;
        },
        SET_COUNTER_TYPE(state, payload) {
            state.counterType = payload;            
        },
        SET_REGISTER_PARAM(state, payload) {
            state.registerParam = payload;
        },
        SET_IS_FORM_VALID(state, payload) {
            state.isFormValid = payload;
        },
    },
    actions: {
        async open({ state, commit, dispatch, rootGetters }) 
        {
            try
            {
                dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
                
                let documentId = rootGetters['actionsource/getDataSource'].Data.Object.Document.id;
                
                let response = await httpAPI({
                    url: '/api/csc/requests/registerparam',
                    method: 'POST',
                    data: { Content : JSON.stringify({ id: documentId, param: { PrevYear: false }}) },
                    headers: { 'Content-Type': 'application/json' }
                });
                
                commit('SET_REGISTER_PARAM', response.data.payload.Data.Object);
                commit('SET_IS_FORM_VALID', state.registerParam.SelectedCounter != null);

                console.log(response);                
            }
            catch (exception)
            {   
                console.error(exception)             
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false, text: `Загрузка...` }, { root: true });
            }

            commit('SET_VISIBLE', true);
            
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ state, commit, dispatch, getters, rootGetters }) 
        {
            commit('SET_VISIBLE', false);

            try
            {
                dispatch('setOverlayVisible', { visible: true, text: `Регистрация...` }, { root: true });

                if (getters.GetCounterType == 2)
                {
                    let registerParam = getters.getRegisterParam;
                    registerParam.FromReserv = true;
                    registerParam.ReserveItem = this.reserveItem;
                    commit('SET_REGISTER_PARAM', registerParam);
                }

                let documentId = rootGetters['actionsource/getDataSource'].Data.Object.Document.id;

                let response = await httpAPI({
                    url: '/api/csc/requests/register',
                    method: 'POST',
                    data: { Content : JSON.stringify({ id: documentId, param: getters.getRegisterParam }) },
                    headers: { 'Content-Type': 'application/json' }
                });
                
                state.resolve(response.data.payload);
            }
            catch (exception)
            {   
                console.log(exception)  
                state.reject();
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false, text: `Загрузка...` }, { root: true });
            }
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ isCancelled: true });
        },
        setReserveItem({ commit }, v) {
            commit('SET_RESERVE_ITEM', v);
        },
        setIsFormValid({ commit }, v) {
            commit('SET_IS_FORM_VALID', v);
        },
    },
    getters: {
        getVisible: (s) => s.visible,
        getReserveItem: (s) => s.reserveItem,
        getRegisterParam: (s) => s.registerParam,
        getIsFormValid: (s) => s.isFormValid,
        getCounterType: (s) => s.counterType,
    }
}

export default cscRegistry;