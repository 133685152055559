import AWN from "awesome-notifications";

const options = {
    icons: {
        enabled: false
    }
}

export default {
    install(Vue, i18n) {
        const notify = new AWN(options);

        Vue.prototype.$notify =
        {
            success: (text, optionsOverrides) => {
                let opts = { labels: { success: i18n.t('Успешно') }, ...optionsOverrides, ...options };
                notify.success(text ?? i18n.t('Действие_успешно_выполнено'), opts);
            },
            warning: (text, optionsOverrides) => {
                let opts = { labels: { warning: i18n.t('Внимание') }, ...optionsOverrides, ...options };
                notify.warning(text ?? i18n.t('Действие_выполнено_частично'), opts);
            },
            alert: (text, optionsOverrides) => {
                let opts = { labels: { alert: i18n.t('Ошибка') }, ...optionsOverrides, ...options };
                notify.alert(text ?? i18n.t('При_выполнении_действия_произошла_ошибка'), opts);
            },
            info: (text, optionsOverrides) => {
                let opts = { labels: { info: i18n.t('Информация') }, ...optionsOverrides, ...options };
                notify.info(text ?? i18n.t('Информация'), opts);
            },
            tip: (text, optionsOverrides) => {
                let opts = { labels: { tip: i18n.t('Подсказка') }, ...optionsOverrides, ...options };
                notify.tip(text ?? i18n.t('Подсказка'), opts);
            },
            confirm: (message, onOk, onCancel, optionsOverrides) => 
            {
                let opts = { labels: { confirm: i18n.t('Требуется_подтверждение'), confirmOk: i18n.t('Ок'), confirmCancel: i18n.t('Отмена') }, ...optionsOverrides, ...options };
                notify.confirm(message, onOk, onCancel, opts);
            },
            modal: (text, className, optionsOverrides) => {
                notify.modal(i18n.t(text), className, optionsOverrides);
            },
            async: (promise, onResolve, onReject, message, optionsOverrides) => {
                let opts = { labels: { async: i18n.t('Отчет_формируется...') }, ...optionsOverrides };
                notify.async(promise, onResolve, onReject, message, opts);
            },
            closeToasts: () => notify.closeToasts()
        };
    }
}