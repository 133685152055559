import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import { httpAPI } from "@/api/httpAPI";
import store from '@/store';
import i18n from '@/i18n';

const signClient = axios.create({
    baseURL: 'http://127.0.0.1:15000/SignService/'
});
const alias = 'Avrora3';
const legacySignRequest = async function(params) {
    try
    {
        const t = encodeURIComponent(params.token);

        let response = await axios({
            url: `http://127.0.0.1:15000/sign?doc_id=${params.id}&host=${params.endpoint}&token=${t}&alias=${alias}`,
            method: 'GET',
            withCredentials: true,
            adapter: jsonpAdapter,
        });

        return { success: true, response };
    }
    catch (ex)
    {
        return { success: false, error: ex };
    }
};
const tumarProfilesRequest = function(password) {
    return signClient({
        url: `TumarProfiles`,
        method: "POST",
        data: { password },
        withCredentials: true,
    });
};
const tumarDistinguishedNamesRequest = function(grant, profile) {
    return signClient({
        url: `TumarProfileInfo`,
        method: "POST",
        data: { grant, profile },
        withCredentials: true,
    });
};
const tumarSignDataRequest = function(grant, profile, dn, data) {
    return signClient({
        url: `SignData`,
        method: "POST",
        data: { grant, profile, dn, data },
        withCredentials: true,
    });
};
const tumarCertificateInfoRequest = function(grant, profile, dn) {
    return signClient({
        url: `CertificateInfo`,
        method: "POST",
        data: { grant, profile, dn },
        withCredentials: true,
    });
};
const handshakeRequest = function(password) {
    return signClient({
        url: `Handshake`,
        method: "POST",
        data: { password },
        withCredentials: true,
    });
};
const signDocumentRequest = function(grant, profile, id, signType, host, token) {
    return signClient({
        url: `SignDocument`,
        method: "POST",
        data: { grant, profile, id, signType, host, token },
        withCredentials: true
    });
};
const signDocumentNICRequest = function(grant, id, signType, host, token) {
    return signClient({
        url: `SignDocumentNIC`,
        method: "POST",
        data: { grant, id, signType, host, token },
        withCredentials: true
    });
};
const signNotifyRejectRequest = function(grant, profile, host, token, reason) {
    return signClient({
        url: `SignNotifyReject`,
        method: "POST",
        data: { grant, profile, host, token, reason},
        withCredentials: true
    });
};
const signNotifyRegisterRequest = function(grant, profile, host, token, number) {
    return signClient({
        url: `SignNotifyRegister`,
        method: "POST",
        data: { grant, profile, host, token, number},
        withCredentials: true
    });
};
const signDocument = async function (type, dispatch, params) {
    try
    {
        dispatch('setOverlayVisible', { visible: false }, { root: true });
        let password = process?.env?.VUE_APP_TUMAR_PROMPT_PASSWORD === "true"
            ? await dispatch('promptPassword')
            : await dispatch('getSHA256Secret');

        if (typeof type === "string" && type == "UCGO") {

            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
            let initial = await tumarProfilesRequest(password);
            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (initial.data?.Result !== 'OK')
                return { success: false, message: initial.data.Message };

            let initialObject = JSON.parse(initial.data.Data.Object);
            let profile = await dispatch('promptProfile', initialObject.Profiles);

            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
            let signResult = await signDocumentRequest(initialObject.Grant, profile, params.id, params.documentType, params.endpoint, params.token);
            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (signResult.data.Result !== 'OK')
                return { success: false, message: signResult.data.Message };

            return { success: true, response: signResult, message: signResult.data.Message };

        }

        if (typeof type === "string" && type == "NIC") {
            
            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
            let initial = await handshakeRequest(password);
            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (initial.data?.Result !== 'OK')
                return { success: false, message: initial.data.Message };

            let initialObject = JSON.parse(initial.data.Data.Object);

            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
            let signResult = await signDocumentNICRequest(initialObject.Grant, params.id, params.documentType, params.endpoint, params.token);
            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (signResult.data.Result !== 'OK')
                return { success: false, message: signResult.data.Message };

            return { success: true, response: signResult, message: signResult.data.Message };
        }
    }
    catch (ex)
    {
        return { success: false, message: ex.message };
    }
    finally
    {
        dispatch('setOverlayVisible', { visible: false }, { root: true });
    }
};
const NIC_onopen = function (event) {
    console.log("[NIC_onopen]: Connection opened", event);
};
const NIC_onclose = function (event) {
    if (event.wasClean)
		console.log('[NIC_onclose]: Connection has been closed');
    else
		console.log('[NIC_onclose]: Connection error');

	console.log(`[NIC_onclose]: Code: ${event.code} Reason: ${event.reason}`);
};
const NIC_onmessage = function (event) {
    var result = JSON.parse(event.data); 

	if (result != null) {
		var rw = {
			code: result['code'],
			message: result['message'],
			responseObject: result['responseObject'],
			getResult: function () {
				return this.result;
			},
			getMessage: function () {
				return this.message;
			},
			getResponseObject: function () {
				return this.responseObject;
			},
			getCode: function () {
				return this.code;
			}
		};

		if (store.getters['signing/getNICCallback'] != null) {
			store.getters['signing/getNICCallback'](rw);
		}
	}
};
const NIC_createCAdESFromBase64Call = function (connection, storageName, base64ToSign) {
    var createCAdESFromBase64 = {
		"module": "kz.gov.pki.knca.commonUtils",
		"method": "createCAdESFromBase64",
		"args": [storageName, "SIGNATURE", base64ToSign, false]
	};
	connection.send(JSON.stringify(createCAdESFromBase64));
    store.commit('signing/SET_NIC_CALLBACK', NIC_createCAdESFromBase64Back);
};
const NIC_createCAdESFromBase64Back = function(result) {
	if (result['code'] === "500") {
        store.state.signing.NIC_reject({ message: result['message'] })
	}
    else if (result['code'] === "200") {
        let response = result['responseObject'];

		if (response != null && typeof response === 'string' && response.length > 200)
            store.state.signing.NIC_resolve({ sign: response });
        else
            store.state.signing.NIC_reject({ message: "Не_удалось_получить_данные_подписи" });
	}
};
const NIC_getActiveTokensCall = function(connection) {
    store.commit('signing/SET_PROMPT_PROFILE_LOADING', true);    
    var getActiveTokens = {
        "module": "kz.gov.pki.knca.commonUtils",
        "method": "getActiveTokens"
    };    
    connection.send(JSON.stringify(getActiveTokens));
    store.commit('signing/SET_NIC_CALLBACK', NIC_getActiveTokensBack);
};
const NIC_getActiveTokensBack = function(result) {    
    let listOfTokens = ['PKCS12'];
    
    if (result['code'] === "200")
		listOfTokens = listOfTokens.concat(result['responseObject']);
    else
        alert(result['message']);

    store.commit('signing/SET_PROFILES', listOfTokens);
    store.commit('signing/SET_PROMPT_PROFILE_LOADING', false);
}
const UCGO_DEFAULT_PROFILE = "profile://ESEDO004";

const signing =
{
    namespaced: true,
    state: {
        promptPasswordVisible: false,
        promptPasswordResolve: null,
        promptPasswordReject: null,
        password: null,

        promptProfileVisible: false,
        promptProfileLoading: false,
        promptProfileResolve: null,
        promptProfileReject: null,
        profile: null,
        profiles: [],

        meta: null,

        NIC_WebSocketConnection: null,
        NIC_callback: null,
        NIC_resolve: null,
        NIC_reject: null
    },
    mutations: {
        SET_META(state, payload) {
            state.meta = payload;
        },
        SET_PROMPT_PASSWORD_VISIBLE(state, payload) {
            state.promptPasswordVisible = payload;
        },
        SET_PROMPT_PASSWORD_RESOLVE(state, payload) {
            state.promptPasswordResolve = payload;
        },
        SET_PROMPT_PASSWORD_REJECT(state, payload) {
            state.promptPasswordReject = payload;
        },
        SET_PASSWORD(state, payload) {
            state.password = payload;
        },
        SET_PROMPT_PROFILE_VISIBLE(state, payload) {
            state.promptProfileVisible = payload;
        },
        SET_PROMPT_PROFILE_LOADING(state, payload) {
            state.promptProfileLoading = payload;
        },
        SET_PROMPT_PROFILE_RESOLVE(state, payload) {
            state.promptProfileResolve = payload;
        },
        SET_PROMPT_PROFILE_REJECT(state, payload) {
            state.promptProfileReject = payload;
        },
        SET_PROFILE(state, payload) {
            state.profile = payload;
        },
        SET_PROFILES(state, payload) {
            state.profiles = payload;
        },
        SET_NIC_WEB_SOCKET_CONNECTION(state, payload) {
            state.NIC_WebSocketConnection = new WebSocket(payload);            
            state.NIC_WebSocketConnection.onopen = NIC_onopen;
            state.NIC_WebSocketConnection.onclose = NIC_onclose;
            state.NIC_WebSocketConnection.onmessage = NIC_onmessage;
        },
        SET_NIC_CALLBACK(state, payload) {
            state.NIC_callback = payload;
        },
        SET_NIC_RESOLVE(state, payload) {
            state.NIC_resolve = payload;
        },
        SET_NIC_REJECT(state, payload) {
            state.NIC_reject = payload;
        },
    },
    actions: {
        async signDocumentUCGO({ state, commit, dispatch }, { id, documentType } ) {

            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });

            //если данные для начала процесса подписания еще не получены
            if (!state.meta) {

                //пытаемся их получить
                let signingMetaData = await httpAPI({
                    url: '/api/references/signmetadata',
                    method: 'GET'
                });

                if (signingMetaData)
                    commit('SET_META', signingMetaData.data.payload);
                else
                    return { success: false, message: null };
            }

            //проверяем, нужно ли отправлять запрос на старую версию ариадны
            if (state.meta.checkLegacy === true)
            {
                
                let legacySign = await legacySignRequest({ id, endpoint: state.meta.endpoint, token: state.meta.token });

                if (legacySign.success)
                {
                    switch (legacySign.response.data.Result)
                    {
                        //кейс, если сервер уже обновлен для работы со службой, но у клиента еще нет новой службы, подписано успешно
                        case "OK":
                        {
                            dispatch('setOverlayVisible', { visible: false }, { root: true });
                            return { success: true, message: legacySign.response.data.Message };
                        }
                        //кейс, если сервер уже обновлен для работы со службой, и у клиента уже есть новая служба
                        case "DEPRECATED":
                        {
                            return await signDocument('UCGO', dispatch, { id, documentType, endpoint: state.meta.endpoint, token: state.meta.token });
                        }
                        //кейс, если сервер уже обновлен для работы со службой, но у клиента еще нет новой службы, ошибка в процессе подписания
                        case "ERROR":
                        {
                            dispatch('setOverlayVisible', { visible: false }, { root: true });
                            return { success: false, message: legacySign.response.data.Message };
                        }

                        default:
                            break;
                    }
                }
                else
                {
                    dispatch('setOverlayVisible', { visible: false }, { root: true });
                    return { success: false, message: legacySign.error.message };
                }
            }
            else
            {
                return await signDocument('UCGO', dispatch, { id, documentType, endpoint: state.meta.endpoint, token: state.meta.token });
            }
        },
        async signSimple({ dispatch }, id) {
            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });

            let response = await httpAPI({
                url: `/api/actions/signsimple/${id}`,
                method: 'POST'
            });

            dispatch('setOverlayVisible', { visible: false }, { root: true });

            if (!response)
                return { success: false };

            return { success: true, message: response.data.payload.Message };
        },
        async signNotification({ dispatch, commit, state }, { notificationType, dataToSign }) {
            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });

            //если данные для начала процесса подписания еще не получены
            if (!state.meta) {

                //пытаемся их получить
                let signingMetaData = await httpAPI({
                    url: '/api/references/signmetadata',
                    method: 'GET'
                });

                if (signingMetaData)
                    commit('SET_META', signingMetaData.data.payload);
                else
                    return { success: false, message: null };
            }

            try
            {                
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                //запрашиваем пароль
                let password = process?.env?.VUE_APP_TUMAR_PROMPT_PASSWORD === "true"
                    ? await dispatch('promptPassword')
                    : await dispatch('getSHA256Secret');

                //получаем данные профайлов и ключ доступа к сервису
                dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
                let initial = await tumarProfilesRequest(password);
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (initial.data.Result !== 'OK')
                    return { success: false, message: initial.data.Message };

                let initialObject = JSON.parse(initial.data.Data.Object);
                //запрашиваем профайл
                let profile = await dispatch('promptProfile', initialObject.Profiles);
                dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });
                let signResult = null;

                switch (notificationType)
                {
                    case 'REJECT':
                        signResult = await signNotifyRejectRequest(initialObject.Grant, profile, state.meta.endpoint, state.meta.token, dataToSign);
                        break;

                    case 'REGISTER':
                        signResult = await signNotifyRegisterRequest(initialObject.Grant, profile, state.meta.endpoint, state.meta.token, dataToSign);
                        break;

                    default:
                        break;
                }
                
                dispatch('setOverlayVisible', { visible: false }, { root: true });

                if (signResult.data.Result !== 'OK')
                    return { success: false, message: signResult.data.Message };

                return { success: true, message: signResult.data.Message };
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
            finally
            {
                dispatch('setOverlayVisible', { visible: false }, { root: true });
            }
        },
        async promptPassword({ commit }) {
            commit('SET_PASSWORD', null);
            commit('SET_PROMPT_PASSWORD_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_PROMPT_PASSWORD_RESOLVE', resolve);
                commit('SET_PROMPT_PASSWORD_REJECT', reject);
            });
        },
        async passwordOk({ commit, state }) {
            commit('SET_PROMPT_PASSWORD_VISIBLE', false);
            state.promptPasswordResolve(state.password);
        },
        async passwordCancel({ commit, state }) {
            commit('SET_PROMPT_PASSWORD_VISIBLE', false);
            state.promptPasswordReject({ message: i18n.t("Ввод_пароля_отменен") });
        },
        async promptProfile({ commit, rootGetters }, profiles) {
            let userProfile = new String(rootGetters['auth/getUserInfo']?.tumarProfile);
            let upperCaseProfiles = Array.from(profiles).map(x => new String(x).toUpperCase());

            if (!!userProfile && upperCaseProfiles.includes(userProfile.toUpperCase()))
                return Promise.resolve(userProfile);

            if (profiles.includes(UCGO_DEFAULT_PROFILE)) {
                commit('SET_PROFILE', UCGO_DEFAULT_PROFILE);
            }
            else {
                commit('SET_PROFILE', null);
            }

            commit('SET_PROFILES', profiles);
            commit('SET_PROMPT_PROFILE_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_PROMPT_PROFILE_RESOLVE', resolve);
                commit('SET_PROMPT_PROFILE_REJECT', reject);
            });
        },
        async profileOk({ commit, state }) {
            commit('SET_PROMPT_PROFILE_VISIBLE', false);
            state.promptProfileResolve(state.profile);
        },
        async profileCancel({ commit, state }) {
            commit('SET_PROMPT_PROFILE_VISIBLE', false);
            state.promptProfileReject({ message: "Выбор_профайла_отменен" });
        },
        openNICConnection({ state, commit }) {
            if (state.NIC_WebSocketConnection == null) {
                commit('SET_NIC_WEB_SOCKET_CONNECTION', 'wss://127.0.0.1:13579/');
            }
        },
        async signDocumentNIC({ state, commit }, { data }) {
            return new Promise((resolve, reject) => {
                (async () => {
                    commit('SET_NIC_RESOLVE', resolve);
                    commit('SET_NIC_REJECT', reject);
                    
                    if (state.NIC_WebSocketConnection == null || state.NIC_WebSocketConnection.readyState != 1) {
                        reject({ message: "Нет соединения с NCALayer!" })
                        return;
                    }

                    if (data == null || typeof data !== 'string' || data.length < 200) {
                        reject({ message: 'Недостаточно_данных_для_подписания_договора' })
                        return;
                    }
                    
                    try
                    {
                        NIC_getActiveTokensCall(state.NIC_WebSocketConnection);
                        let profile = await store.dispatch('signing/promptProfile', []);
                        NIC_createCAdESFromBase64Call(state.NIC_WebSocketConnection, profile, data);
                    }
                    catch(ex)
                    {
                        reject({ message: ex.message });
                        return;
                    }
                })();
            });
        },
        async signDocumentNICWithAriadna({ state, commit, dispatch }, { id, documentType }) {
            dispatch('setOverlayVisible', { visible: true, text: `Загрузка...` }, { root: true });

            //если данные для начала процесса подписания еще не получены
            if (!state.meta) {
                //пытаемся их получить
                let signingMetaData = await httpAPI({
                    url: '/api/references/signmetadata',
                    method: 'GET'
                });

                if (signingMetaData)
                    commit('SET_META', signingMetaData.data.payload);
                else
                    return { success: false, message: null };
            }

            return await signDocument('NIC', dispatch, { id, documentType, endpoint: state.meta.endpoint, token: state.meta.token });
        },
        async fetchTumarHandshake(_, { password }) {
            try
            {
                var req =  await handshakeRequest(password);

                if (req.data?.Result !== 'OK')
                    return { success: false, message: req.data.Message };

                return JSON.parse(req.data.Data.Object);
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
        },
        async fetchTumarProfiles(_, { password }) {
            try
            {
                var req =  await tumarProfilesRequest(password);

                if (req.data?.Result !== 'OK')
                    return { success: false, message: req.data.Message };

                return JSON.parse(req.data.Data.Object);
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
        },
        async fetchTumarProfileDistinguishedNames(_, { grant, profile }) {
            try
            {
                var req =  await tumarDistinguishedNamesRequest(grant, profile);

                if (req.data?.Result !== 'OK')
                    return { success: false, message: req.data.Message };

                return JSON.parse(req.data.Data.Object);
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
        },
        async fetchTumarSignData(_, { grant, profile, dn, data }) {
            try
            {
                var req =  await tumarSignDataRequest(grant, profile, dn, data);

                if (req.data?.Result !== 'OK')
                    return { success: false, message: req.data.Message };

                return JSON.parse(req.data.Data.Object);
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
        },
        async fetchTumarCertificateInfoRequest(_, { grant, profile, dn }) {
            try
            {
                var req =  await tumarCertificateInfoRequest(grant, profile, dn);

                if (req.data?.Result !== 'OK')
                    return { success: false, message: req.data.Message };

                return JSON.parse(req.data.Data.Object);
            }
            catch (ex)
            {
                return { success: false, message: ex.message };
            }
        },
        getSHA256Secret() {
            return "47e5a75df97fc7cf4089eb93363a69a214f747b9a5d13426033635ec9e34b05b";
        }
    },
    getters: {
        isPromptPasswordVisible: (s) => s.promptPasswordVisible,
        isPromptProfileVisible: (s) => s.promptProfileVisible,
        getPassword: (s) => s.password,
        getProfile: (s) => s.profile,
        isPromptProfileLoading: (s) => s.promptProfileLoading,
        getProfiles: (s) => s.profiles.map(x => ({ id: x, Value: x.replace("profile://", "") })),
        getNICCallback: (s) => s.NIC_callback
    }
}

export default signing;