<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="loading">

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text v-else class="wrapperFormModal">
                <v-card
                    flat
                >
                    <v-card-text>

                        <div class="form-box-title">{{ $t("Выберите_версию_документа") }}</div>

                        <v-list class="modal-select-list" dense outlined>

                            <v-list-item-group
                                v-model="selected"
                                color="cyan darken-1"
                            >
                                <v-list-item
                                    v-for="item in versions"
                                    :key="item.id"
                                    :value="item"
                                >
                                    <!-- Иконка маршрута -->
                                    <v-list-item-avatar>
                                        <v-icon
                                        >
                                            fas fa-file-alt
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>
                                            {{ `${$t("Версия")} ${item.version}` }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ versions.indexOf(item) + 1 === versions.length ? `${$t("Текущая_версия")} ` : "" }}{{ `${$t("от_время")} ${item.created}` }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                            
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="selected && !selected.active"
                >
                    {{ $t("Открыть") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "DocumentVersionsDlg",
    data: () => ({
        title: "Версии",

        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        options: {
            color: 'grey lighten-3',
            width: 400,
            zIndex: 200,
            noconfirm: false,
        },

        selected: null,
        versions: []
    }),
    methods: {
        async open(documentId) {
            this.selected = null,
            this.loading = true;
            this.visible = true;

            let response = await httpAPI({
                url: `/api/projects/versionslist/${documentId}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] }
            });

            this.versions = response?.data?.payload?.Data?.Object?.Data?.map(x => ({ id: x[0], version: x[1], created: x[2], active: documentId === x[0] })) ?? [];
            this.selected = this.versions.find(x => x.active);

            this.loading = false;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        ok() {
            this.visible = false;
            this.resolve({ ...this.selected, common: this.$store.getters['actionsource/isDataSourceCommon'] });
        },
        cancel() {
            this.visible = false;
            this.reject({ message: 'Cancelled' });
        }
    }
}
</script>