<template>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="6282" height="5452" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
    viewBox="-100 -100 6482 5552"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Layer_x0020_1">
    <metadata id="CorelCorpID_0Corel-Layer"/>
    <g id="_942836224">
    <path class="fil0" d="M4480.44 2119.85c17.42,1.3 34.97,4.41 52.42,9.42 39.69,11.39 74.59,31.39 103.02,57.59 102.83,-18.62 202.14,24.62 258.77,113.18 355.19,555.44 745.09,1098.36 1079.1,1665.59 195.99,332.84 557.32,949.85 306.32,1329 -133.78,202.08 -373.99,254.47 -601.25,252.47l-748.06 19.63c-72.8,1.91 -139.33,-25.78 -187.12,-74.21 -46.77,47.94 -109.64,74.3 -180.17,74.3l-2596.47 0c-123.41,0 -226.94,-85.95 -249.65,-207.25 -5.23,-27.92 -5.79,-55.64 -2.15,-82.31 -12.27,0.71 -24.5,1.07 -36.69,1.07 -90.45,0 -177.14,-24.25 -253.2,-73.49 -90.82,-58.78 -153.22,-139.99 -196.07,-233.49 28.65,81 61.91,157.35 96.36,221.93 43.19,80.97 40.91,171.52 -6.3,250.21 -47.21,78.7 -126.03,123.33 -217.8,123.33l-594.46 0c-8.1,0 -15,-0.31 -23.06,-1.05 -164.32,-14.98 -329.28,-59.3 -440.89,-189.75 -269.15,-314.61 23.36,-833.84 192.7,-1125.33 136.28,-234.6 265.63,-472.78 403.89,-706.5 140.88,-238.14 340.06,-576.28 538.99,-765.97 69.69,-66.46 166.3,-86.98 253.63,-58.09 60.94,-196.6 127.62,-391.49 198.18,-583.67 185.39,-504.96 408.64,-1029.23 676.94,-1495.94 154.81,-269.29 457.38,-774.6 813.58,-774.6 5.89,0 10.91,0.17 16.78,0.56 202.41,13.4 370.29,198.43 489.08,346.73 142.41,177.78 269.45,377.33 387.64,571.96 114.17,187.99 222.64,379.32 329.87,571.34 38.77,69.44 77.3,139.06 116.54,208.24 15.02,26.46 31.55,57.59 48.72,84.27 43.22,45.65 68.94,107.25 68.94,174.82 0,67.71 -42,178.1 -98.13,296.01zm-3330.83 2536.64c-5.18,-46.25 -8.45,-92.85 -10.49,-139.01 0,0.67 0,1.33 0,2 0,43.26 3.73,89.4 10.49,137.01z"/>
    </g>
    <path class="fil1" d="M1390.02 4230.97c0,135.01 -46.15,793.37 288.49,793.37 900.7,0 2646.07,-3016.54 2646.07,-3200.5 -45.08,-8.9 -863.07,-1681.38 -1203.58,-1703.93 -524.43,0 -1730.98,2946.05 -1730.98,4111.06zm-288.52 1081.86c-87.73,-164.45 -216.37,-498.12 -216.37,-793.35 0,-572.28 330.21,-1151.06 468.82,-1557.45 -262.65,250.46 -696.74,1069.55 -898.54,1416.24 -427.16,735.98 -295.52,902.91 51.63,934.56l594.46 0zm3461.97 -1586.73c0,1163.8 278.29,1327.54 360.63,1586.73l752.72 -19.75c1075.28,13.29 -59.15,-1390.92 -996.15,-2856.21 -61.3,372.2 -82.48,832.18 -117.2,1289.23zm-2596.47 1586.73l2596.47 0c-387.96,-733.22 -454.89,-1148.81 -290.17,-2093.29 47.6,-272.99 167.91,-586.89 189.49,-846.14 -616.45,1195.92 -1496.11,2551.47 -2495.79,2939.43z"/>
    </g>
    </svg>
</template>
<script>
export default {
    name: "IntegroLogo",
}
</script>

<style type="text/css">

    .fil1 {fill:rgb(73, 79, 89)}
    .fil0 {fill:transparent}

</style>
