const pdfView = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        url: null,
        name: "Report",
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_URL(state, payload) {
            state.url = payload;
        },
        SET_NAME(state, payload) {
            state.name = payload;
        },
    },
    actions: {
        async open({ commit }, { url, name }) {
            
            commit('SET_VISIBLE', true);
            commit('SET_URL', url);
            commit('SET_NAME', name ? name : "Report");

            return new Promise((resolve) => {
                commit('SET_RESOLVE', resolve);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve();
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getUrl: (s) => s.url,
        getName: (s) => s.name
    }
}

export default pdfView;