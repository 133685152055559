import moment from 'moment';
import i18n from '@/i18n'
import Axios from 'axios';


export default {
    guidEmpty(){
        return '00000000-0000-0000-0000-000000000000'
    },
    generateUUID() {
        // Public Domain/MIT
        var d = new Date().getTime(); //Timestamp
        var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) {
                //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    },
    timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },
    dateFormat(date, returnFormat, dateFormat){
        var dateMoment = moment(date, dateFormat);

        if (dateMoment != null && dateMoment.isValid()) {
            return dateMoment.format(returnFormat);            
        }

        return null;
    },
    isEmpty() {
        let result = [...arguments].reduce((acc, val) => {
            if (!acc)
                return acc;

            acc = (!val?.trim() || val?.trim()?.length === 0 )

            return acc;
        }, true);   
        return result;
    },
    isNotEmpty() {
        let result = [...arguments].reduce((acc, val) => {
            if (!acc)
                return acc;
            
            acc = !(!val?.trim() || val?.trim()?.length === 0)

            return acc;

        }, true);   
        return result;
    },
    checkSearch(source, search) {

        if(source == null)
            return false;

        let sourceLowerCase = source.toLowerCase();  
        let searchLowerCase = search.toLowerCase();
        let searchTokens = searchLowerCase.split(' ');
      
        return sourceLowerCase.includes(searchLowerCase) || searchTokens.every((e) => sourceLowerCase.includes(e));
    },
    MAX_FILE_NAME_CHAR_COUNT: 100,
    MAX_FILE_SIZE: 15728640,
    MAX_FILES_SIZE: 99614720,
    MAX_FILE_COUNT: 80,
    FORBIDDEN_EXTENSIONS: [
        "ade",
        "adp",
        "gz",
        "tar",
        "bat",
        "chm",
        "cmd",
        "com",
        "cpl",
        "exe",
        "hta",
        "ins",
        "isp",
        "jse",
        "lib",
        "lnk",
        "mde",
        "msc",
        "msp",
        "mst",
        "pif",
        "scr",
        "sct",
        "shb",
        "sys",
        "vb",
        "vbe",
        "vbs",
        "vxd",
        "wsc",
        "wsf",
        "wsh",
        "apx",
        "dll",
        "url"
    ],
    FORBIDDEN_SEQUENCES: [
        "!!"
    ],
    prepareAttachment(file, dataSource, notify) 
    {
        var attachment = {
            file: file,
            Id: this.generateUUID(),
            Name: file.name,   
            Length: file.size,
            Group: 0
        };
        
        if (file.name.length > this.MAX_FILE_NAME_CHAR_COUNT)
            attachment.Message = i18n.t("Превышена_допустимая_длина_имени_файла_limit.message", { limit: this.MAX_FILE_NAME_CHAR_COUNT });

        if (!attachment.Message && this.FORBIDDEN_SEQUENCES.some(seq => file.name.includes(seq)))
            attachment.Message = i18n.t("Недопустимое_имя_файла");

        if (!attachment.Message && file.size > this.MAX_FILE_SIZE)
            attachment.Message = i18n.t("Превышен_лимит_размера_вложения_limit.message", { limit: '15 Мб' });
        
        let currentDSSize = dataSource.reduce((dsSize, file) => {
            if (!!file.Message || !!file.Progress)
                return dsSize;
            else
                return dsSize += file.Length;
        }, 0);

        if (!attachment.Message && currentDSSize + file.size > this.MAX_FILES_SIZE)
            attachment.Message = i18n.t("Превышен_лимит_размера_вложений_limit.message", { limit: '95 Мб' });
        
        //Закомментировано по задаче Iceblooms/AvroraIssuesRepo#74
        // if (!attachment.Message && dataSource.length + 1 > this.MAX_FILE_COUNT)
        //     attachment.Message = i18n.t("Превышен_лимит_количества_вложений_limit.message", { limit: this.MAX_FILE_COUNT });

        var fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase()
        
        if(!attachment.Message && this.FORBIDDEN_EXTENSIONS.includes(fileExtention))
            attachment.Message = i18n.t("Недопустимое_расширение_файла");

        if(!attachment.Message && dataSource.find(i => i.Name == attachment.Name))
            attachment.Message = i18n.t("Вложение_с_таким_именем_уже_существует");

        if (!attachment.Message)
        {
            attachment.Progress = '0%';
            attachment.CancelTokenSource = Axios.CancelToken.source();
        }
        else
        {
            notify.alert(i18n.t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: attachment.Name, reason: attachment.Message }));
        }
        
        return attachment;
    },
    prepareResolutionTaskExecuter(taskId, index, selectedMember) {
        // если орг-ция ExecuterID = Guid.Empty
        // EnterpriseID должен быть заполнен всегда
        // IsInner - true, если из моей орг-ции, остальные false
        return {
            __type: "ResolutionTaskExecuter:#Avrora.Objects.Modules.Docflow.DocflowObjects",
            ParentId: taskId,
            ExecuterID: selectedMember.type === 1 ? selectedMember.workplaceId : this.guidEmpty(),
            ExecuterName: selectedMember.name,
            IsInner: selectedMember.inner,
            EnterpriseID: selectedMember.enterprise,
            EnterpriseName: selectedMember.type === 1 ? "" : selectedMember.name,
            WorkStatus: 0, // константа
            IndexInTask: index,
            Svod: false, // константа
            DelRec: false, // константа
            is_new_record: true
        };
    },
    hashCode(str) {
        var hash = 0,
          i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
          chr = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },
    getNameFromDN(DNstring) {
        let name = "";

        if (!DNstring)
            return name;
        
        let separator = ",";
        let CN_MASK = "CN=";
        let GIVENNAME_MASKS = ["GIVENNAME=", "G="];

        let tokens = DNstring.split(separator);
        let CNToken = tokens.find(x => x.startsWith(CN_MASK));

        if (typeof CNToken !== 'undefined')
            name = CNToken.replace(CN_MASK, "");

        for (let GIVENNAME_MASK of GIVENNAME_MASKS) {
            let GIVENNAMEToken = tokens.find(x => x.startsWith(GIVENNAME_MASK));

            if (typeof GIVENNAMEToken !== 'undefined') {
                let givenname = GIVENNAMEToken.replace(GIVENNAME_MASK, "");

                if (givenname) {
                    name = name.concat(" ", givenname);
                }

                break;
            }
        }
    
        return name;
    }
};