import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

const iqalacomments = {
    namespaced: true,    
    state: {
        isActive: false,
        isPending: null,
        cancellationTokenSorce: null,
        dataSource: [],
    },
    mutations: {
        SET_IS_ACTIVE(state, payload) {
            state.isActive = payload;
        }, 

        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },   

        SET_CANCELLATION_TOKEN_SOURCE(state, payload) {
            state.cancellationTokenSorce = payload;
        },   

        SET_DATASOURCE(state, payload) {
            state.dataSource = payload;
        },        
    },
    actions: {  
        setPreviewSrc({ commit }, previewSrc) {
            commit({ type: 'SET_PREVIEW_SRC', previewSrc });
        },
        setDataSource({commit}, dataSource) {
            commit({ type: 'SET_DATASOURCE', dataSource });
        },
        async updateDataSource({ state, commit, rootGetters }) {

            if (state.cancellationTokenSorce)
                state.cancellationTokenSorce.cancel('New request started');

            commit('SET_CANCELLATION_TOKEN_SOURCE', Axios.CancelToken.source());

            let response = await httpAPI({
                url: `api/csc/requests/getcomments?id=${rootGetters['actionsource/getDataSourceDocumentId']}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response)
                commit('SET_DATASOURCE', response?.data.payload.Data.Object);
            else
                commit('SET_DATASOURCE', []);
        }, 
        setIsActive({commit}, isActive) {
            commit('SET_IS_ACTIVE', isActive);
        }
    },
    getters: {
        getIsActive: s => s.isActive,
        getIsPending: s => s.isPendig,
        getDataSource: s => s.dataSource,        
    },
};

export default iqalacomments;
