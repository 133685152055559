import sys from '@/services/system';

const cscFormAnAnswer = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        objectName: null,
        objectAddress: null,
        connectionPoint: null,

        totalWastewater: null,
        including: null,

        serviceCode: null
    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },

        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },

        SET_OBJECT_NAME(state, payload) {
            state.objectName = payload;
        },
        SET_OBJECT_ADDRESS(state, payload) {
            state.objectAddress = payload;
        },
        SET_CONNECTION_POINT(state, payload) {
            state.connectionPoint = payload;
        },
        SET_TOTAL_WASTEWATER(state, payload) {
            state.totalWastewater = payload;
        },
        SET_INCLUDING(state, payload) {
            state.including = payload;
        },
        SET_SERVICECODE(state, payload) {
            state.serviceCode = payload;
        }
    },
    actions: {
        async open({ commit }, serviceCode) {

            commit('SET_SERVICECODE', serviceCode);
            commit('SET_IS_PENDING', true);
            commit('SET_IS_VISIBLE', true);
            
            //Initialize
            commit('SET_OBJECT_NAME', null);
            commit('SET_OBJECT_ADDRESS', null);
            commit('SET_CONNECTION_POINT', null);
            
            commit('SET_TOTAL_WASTEWATER', null);
            commit('SET_INCLUDING', null);

            commit('SET_IS_PENDING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, rootGetters }) {
            commit('SET_IS_VISIBLE', false);

            let result = {
                DocumentId: rootGetters['actionsource/getDataSource'].Data.Object.Document.id,
                ObjectName: state.objectName,
                ObjectAddress: state.objectAddress,
                ConnectionPoint: state.connectionPoint,
                TotalWastewater: state.totalWastewater,
                Including: state.including
            };
            
            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        setDecision({ commit }, v) {
            commit('SET_DECISION', v);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,
        
        isValid: (s, g) => sys.isNotEmpty(s.objectName, s.objectAddress, s.connectionPoint) && (!g.isExtService || sys.isNotEmpty(s.totalWastewater, s.including)),
        
        objectName: (s) => s.objectName, 
        objectAddress: (s) => s.objectAddress, 
        connectionPoint: (s) => s.connectionPoint,

        serviceCode: (s) => s.serviceCode,

        isExtService: (s) => ["01060001", "01060002", "01060003", "01060004", "01060005", "01060006"].includes(s.serviceCode)
    }
}

export default cscFormAnAnswer;