import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth';
import iqala from '@/store/modules/lists/IQala';
import projects from '@/store/modules/lists/projects';
import inwork from '@/store/modules/in-work/inwork';
import handled from '@/store/modules/handled/handled';
import chancellary from '@/store/modules/chancellary/chancellary';
import tasks from '@/store/modules/tasks/tasks';
import attachments from '@/store/modules/attachments';
import iqalacomments from '@/store/modules/iqalacomments';
import documentcomments from '@/store/modules/documentcomments';
import correspondence from '@/store/modules/correspondence/correspondence';
import executioncontrol from '@/store/modules/execution-control/proxy';
import settings from '@/store/modules/settings/proxy';
import dsp from '@/store/modules/dsp/proxy';

import references from '@/store/references'
import toolbar from '@/store/modules/toolbar';
import actionsource from '@/store/modules/actionsource';

import dialogs from '@/store/modules/dialogs';
import signing from './modules/signing';
import archive from '@/store/modules/archive/archive';

import header from './header';
import npa from '@/store/modules/lists/npa';
import eventManagement from '@/store/modules/event-management';

import { SET_BACKEND_CONNECTION_STATE } from './mutation-types'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    state:
    {
        overlayVisibleValue: false,
        overlayVisible: false,
        overlayText: 'Загрузка...',
        backendConnectionState: null,
        progress: null,
        defaultPagination: 10,
        isLanguageChanging: false,
        hidePreviewWarningMessage: JSON.parse(localStorage.getItem('PREVIEWS_WARN_MSG_HIDDEN') ?? 'false')
    },
    mutations:
    {
        [SET_BACKEND_CONNECTION_STATE] (state, payload) {
            state.backendConnectionState = payload;
        },
        SET_OVERLAY_VISIBLE(state, payload) {
            state.overlayVisible = payload.visible;
        },
        SET_OVERLAY_TEXT(state, payload) {
            state.overlayText = payload.overlayText;
        },
        SET_OVERLAY_PROGRESS(state, payload) {
            state.progress = payload.progress;
        },
        SET_DEFAULT_PAGINATION(state, payload) {
            state.defaultPagination = payload;
        },
        SET_LANGUAGE_CHANGING(state, payload) {
            state.isLanguageChanging = payload;
        },
        SET_HIDE_PREVIEW_WARNING_MESSAGE(state, payload) {
            state.hidePreviewWarningMessage = payload;
            localStorage.setItem('PREVIEWS_WARN_MSG_HIDDEN', payload);
        },
    },
    getters:
    {
        getProgress: (s) => s.progress,
        isLanguageChanging: (s) => s.isLanguageChanging,
        getDefaultPagination: (s) => s.defaultPagination,
        getBackendConnectionState: (s) => s.backendConnectionState,
        overlayVisible: (s) => s.overlayVisible,
        overlayText: (s) => s.overlayText,
        isPreviewWarningMessageHidden: (s) => s.hidePreviewWarningMessage,
        //#region validation rules 
        getRequiredRule: () => {
            return [
                v => (!['string', 'number'].includes(typeof v) && !!v) || (typeof v == 'number' && v >= 0) || (typeof v == 'string' && !!v.trim()) || 'Поле_обязательно'
            ];
        },
        getMultipleRule: () => {
            return [
                v => v.length > 0 || "Поле_обязательно"
            ];
        },
        getIntegerRule: () => {
            return [
                v => ( v >= 0 ) || 'Значение_не_может_быть_меньше_нуля',
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getDefaultGuidRule: () => {
            return [
                v => (v !== "00000000-0000-0000-0000-000000000000" ) || 'Поле_обязательно'
            ];
        },
        getIntegerNotNullRule: () => {
            return [
                v => !!v || 'Поле_обязательно',
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getPhoneRule: () => {
            return [
                v => /^\+?(-*\d){6,11}-*$/.test(v) || 'Некорректный_номер',
            ];
        },
        getIinRule: () => {
            return [
                v => (/^\d+$/.test(v) || v?.length === 0 || !v) || 'Только_числа',
                v => (String(v)?.length == 12 || String(v)?.length === 0 || !v) || '12 символов'
            ];
        },
        getEverydayPeriodRule: () => {
            return [
                v => !!v || 'Поле_обязательно',
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 365 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        getMonthlyPeriodRule: () => {
            return [
                v => !!v || 'Поле_обязательно',
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 12 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        getKvartalPeriodRule: () => {
            return [
                v => !!v || 'Поле_обязательно',
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 60 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        //#endregion
    },
    actions: {
        async setOverlayVisible({ commit }, { visible, text }) 
        {     
            if (this.overlayVisible == visible)
                return

            this.overlayVisibleValue = visible;

            if (visible)
            {
                await (new Promise(resolve => setTimeout(resolve, 200)));
                if (this.overlayVisibleValue != visible)
                    return;
            }
            else
            {
                commit({ type: 'SET_OVERLAY_PROGRESS', progress: null});  
            }

            commit({ type: 'SET_OVERLAY_VISIBLE', visible: this.overlayVisibleValue });
                
            if (text)
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: text });
            else
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: 'Загрузка...' });
        },
        updateProgress({ commit }, { progress }){
            commit({ type: 'SET_OVERLAY_PROGRESS', progress });
        }
    },
    modules: {
        auth,
        iqala,
        inwork,
        handled,
        references,
        projects,
        chancellary,
        tasks,
        correspondence,
        attachments,
        iqalacomments,
        documentcomments,
        executioncontrol,
        settings,
        dsp,
        toolbar,
        actionsource,
        dialogs,
        signing,
        header,
        archive,
        npa,
        eventManagement
    }
});

export default store;

//делаем слепок чистого состояния без реактивностей
const initialState = JSON.stringify(store.state);

//метод для сброса состояние хранилища https://vuex.vuejs.org/api/#replacestate
export function resetStoreState() {
    store.replaceState(JSON.parse(initialState));
}