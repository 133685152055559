import OnAdjustment from './lists/OnAdjustment';
import OnCheck from './lists/OnCheck';
import OnExecution from './lists/OnExecution';
import OnInspect from './lists/OnInspect';
import OnRework from './lists/OnRework';
import OnSign from './lists/OnSign';
import OnControl from './lists/OnControl';
import OnInspectResolution from './lists/OnInspectResolution';

export default {
    namespaced: true,
    state: () => ({
        collection : "Documents.My.OnInspect",
        status: null,
        searches: []
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        SET_STATUS (state, payload) {
            state.status = payload;
        },
        PUSH_SEARCH (state, payload) {
            let searchExists = state.searches.find(x => x.collection == payload.collection);

            if (searchExists) {
                searchExists.value = payload.value;
            } else {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.status;
        },
        getSearches (state) {
            return state.searches;
        }
    },
    modules: {
        adjustmentlist: OnAdjustment,
        checklist: OnCheck,
        executionlist: OnExecution,
        inspectlist: OnInspect,
        reworklist: OnRework,
        signlist: OnSign,
        controllist: OnControl,
        inspectresolutionlist: OnInspectResolution
    }
}