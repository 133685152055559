//import sys from '@/services/system';

const cscFormAnAnswer0103X017 = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        energySupplyOrgId: null,

    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },

        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },

        SET_ENERGY_SAVING_ORG_ID(state, payload) {
            state.energySupplyOrgId = payload;
        },
      
    },
    actions: {
        async open({ commit }) {

            commit('SET_IS_PENDING', true);
            commit('SET_IS_VISIBLE', true);
            
            //Initialize
            commit('SET_ENERGY_SAVING_ORG_ID', null);


            commit('SET_IS_PENDING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, rootGetters }) {
            commit('SET_IS_VISIBLE', false);

            let result =  {
                DocumentId: rootGetters['actionsource/getDataSource'].Data.Object.Document.id,
                EnergySupplyOrgId: state.energySupplyOrgId.id,
            };
            
            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        setDecision({ commit }, v) {
            commit('SET_DECISION', v);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,        
        isValid: (s) => !!s.energySupplyOrgId,
        energySupplygOrgId: (s) => s.energySupplyOrgId, 
    }
}

export default cscFormAnAnswer0103X017;