import { httpAPI } from "@/api/httpAPI";

const registerEuolStatementPrimary = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        currentYearEuolParams: null,
        previousYearEuolParams: null,
        currentYearGovServiceParams: null,
        previousYearGovServiceParams: null,
        currentParams: null,

        registerAsGovService: false,
        prevYear: false,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_CURRENT_YEAR_EUOL_PARAMS(state, payload) {
            state.currentYearEuolParams = payload
        },
        SET_PREVIOUS_YEAR_EUOL_PARAMS(state, payload) {
            state.previousYearEuolParams = payload
        },
        SET_CURRENT_YEAR_GOV_SERVICE_PARAMS(state, payload) {
            state.currentYearGovServiceParams = payload
        },
        SET_PREVIOUS_YEAR_GOV_SERVICE_PARAMS(state, payload) {
            state.previousYearGovServiceParams = payload
        },
        SET_CURRENT_PARAMS(state, payload) {
            state.currentParams = payload;
            //тут нужно поменять параметры уникального номера
            state.currentParams.NeedUniqueNumber = false;
            state.currentParams.PostUniqueNumber = null;
        },
        SET_REGISTER_AS_GOV_SERVICE(state, payload) {
            state.registerAsGovService = payload;
        },
        SET_IS_PREV_YEAR(state, payload) {
            state.prevYear = payload;
        },
        UPDATE_CURRENT_PARAMS(state, payload) {
            this._vm.$_.set(state.currentParams, payload.property, payload.value)
        }
    },
    actions: {
        async open({ commit, dispatch }) {
            commit('SET_REGISTER_AS_GOV_SERVICE', false);
            commit('SET_IS_PREV_YEAR', false);

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);
            
            await dispatch('fetchParams');
            
            commit('SET_LOADING', false);
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {            
            commit('SET_VISIBLE', false);

            let result = JSON.parse(JSON.stringify(state.currentParams));

            result.IsGovService = state.registerAsGovService;

            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async fetchParams({ state, commit, rootGetters }) {

            let response = await httpAPI({
                url: `api/references/euolregisterparams?id=${rootGetters['actionsource/getDataSourceIdentifier']}`,
                method: 'GET'
            });

            if (response)
            {
                commit('SET_CURRENT_YEAR_EUOL_PARAMS', response.data.payload.currentYearEuol.payload?.Data.Object);
                commit('SET_PREVIOUS_YEAR_EUOL_PARAMS', response.data.payload.previousYearEuol.payload?.Data.Object);
                commit('SET_CURRENT_YEAR_GOV_SERVICE_PARAMS', response.data.payload.currentYearGovService.payload?.Data.Object);
                commit('SET_PREVIOUS_YEAR_GOV_SERVICE_PARAMS', response.data.payload.previousYearGovService.payload?.Data.Object);
                commit('SET_CURRENT_PARAMS', state.currentYearEuolParams);
            }
        },
        async onRegisterAsGovServiceChange({ commit, state }, value) {
            if (value)
            {
                state.prevYear
                    ? commit('SET_CURRENT_PARAMS', state.previousYearGovServiceParams)
                    : commit('SET_CURRENT_PARAMS', state.currentYearGovServiceParams);
                
                commit('UPDATE_CURRENT_PARAMS', { property: "NeedUniqueNumber", value: false });
                commit('UPDATE_CURRENT_PARAMS', { property: "PostUniqueNumber", value: null });
            }
            else
            {
                state.prevYear
                    ? commit('SET_CURRENT_PARAMS', state.previousYearEuolParams)
                    : commit('SET_CURRENT_PARAMS', state.currentYearEuolParams);
            }

            commit('UPDATE_CURRENT_PARAMS', { property: "IsGovService", value });
            commit('SET_REGISTER_AS_GOV_SERVICE', value);
        },
        async onPrevYearChange({ commit, state }, value) {
            if (value)
            {
                state.registerAsGovService
                    ? commit('SET_CURRENT_PARAMS', state.previousYearGovServiceParams)
                    : commit('SET_CURRENT_PARAMS', state.previousYearEuolParams);
            }
            else
            {
                state.registerAsGovService
                    ? commit('SET_CURRENT_PARAMS', state.currentYearGovServiceParams)
                    : commit('SET_CURRENT_PARAMS', state.currentYearEuolParams);
            }

            commit('UPDATE_CURRENT_PARAMS', { property: "PrevYear", value });
            commit('SET_IS_PREV_YEAR', value);
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,

        //если в регистрационных параметрах текущего года этого обращения установлен флаг, то даем возможность регистрировать это обращение как гос. услугу
        canRegisterAsGovService: (s) => s.currentYearEuolParams?.IsGovService ?? false,
        isRegisterAsGovService: (s) => s.registerAsGovService,
        isPrevYear: (s) => s.prevYear,
        isFromReserve: (s) => s.currentParams?.FromReserv,
        getReserveItems: (s) => s.currentParams?.Counter?.CounterReserve?.Items ?? [],
        getReserveItem: (s) => s.currentParams?.ReserveItem,
        getRegNumber: (s) => s.currentParams?.RegNumber,
        getPostUniqueNumber: (s) => s.currentParams?.PostUniqueNumber,
        getUniqueNumberParam: (s) => {
            if (s.currentParams?.NeedUniqueNumber === false)
                return 0;
            
            if (s.currentParams?.NeedUniqueNumber === true && s.currentParams?.PostUniqueNumber == null)
                return 1;

            if (s.currentParams?.NeedUniqueNumber === true && s.currentParams?.PostUniqueNumber != null)
                return 2;

            return 0;
        },
        isValid: (s) => 
        {
            if (s.currentParams?.FromReserv === true && s.currentParams?.ReserveItem == null)
                return false;

            if (s.currentParams?.PostUniqueNumber != null && s.currentParams.PostUniqueNumber.length < 13)
                return false;

            return s.currentParams?.Counter != null;
        },
        isCounterValid: (s) => s.currentParams?.Counter != null
    }
}

export default registerEuolStatementPrimary;