const registerSection = {
    namespaced: true,
    state: {
        visible: false,

        resolve: null,
        reject: null,

        minDate: null,
        maxDate: null,

        controlType: null,
        controlDate: null,
        supposedControlDate: null,

        periodDeltaType: 0,
        periodDelta: null,
        isConsiderWeekends: false,
        periodEndDate: null,

        document: ""
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DOCUMENT(state, payload) {
            state.document = payload;
        },
        SET_MIN_DATE(state, payload) {
            state.minDate = this._vm.$moment(payload);
        },
        SET_MAX_DATE(state, payload) {
            state.maxDate = this._vm.$moment(payload);
        },
        SET_CONTROL_TYPE(state, payload) {
            state.controlType = payload;
        },
        SET_CONTROL_DATE(state, payload) {
            state.controlDate = payload ? this._vm.$moment(payload) : null;
        },
        SET_SUPPOSED_CONTROL_DATE(state, payload) {
            state.supposedControlDate = payload ? this._vm.$moment(payload) : null;
        },
        SET_PERIOD_DELTA_TYPE(state, payload){
            state.periodDeltaType = payload ? payload : 0;
        },
        SET_PERIOD_DELTA(state, payload){
            state.periodDelta = payload;
        },
        SET_PERIOD_END_DATE(state, payload) {
            state.periodEndDate = payload ? this._vm.$moment(payload) : null;
        },
        SET_CONSIDER_WEEKEND(state, payload) {
            state.isConsiderWeekends = payload;
        },
    },
    actions: {
        async open({ commit, dispatch }, { document, min, max }) {
            commit('SET_DOCUMENT', document);
            commit('SET_MIN_DATE', min);
            commit('SET_MAX_DATE', max);
            commit('SET_CONTROL_DATE', null);
            commit('SET_CONSIDER_WEEKEND', false);
            commit('SET_PERIOD_END_DATE', null);

            commit('SET_VISIBLE', true);

            dispatch('changeControlType', 1);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ 
                type: state.controlType, 
                date: state.controlDate.format('YYYY-MM-DD'), 
                deltatype: state.periodDeltaType, 
                delta: state.periodDelta, 
                periodend: state.periodEndDate ? state.periodEndDate.format('YYYY-MM-DD') : null,
                isConsiderWeekends: state.isConsiderWeekends 
             });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        changeControlType({ commit, dispatch }, value) {
            commit('SET_CONTROL_TYPE', value);

            switch (value) {
                case 1:
                    dispatch('setControlDate', 30);
                    break;
                case 2:
                    dispatch('setControlDate', 15);
                    break;

                default:
                    break;
            }
        },
        setControlDate({ state, commit }, daysCount) {
            let supposeDate = this._vm.$moment(state.minDate).add(daysCount, 'days');

            if (state.maxDate && state.maxDate.isBefore(supposeDate)) {
                commit('SET_SUPPOSED_CONTROL_DATE', state.maxDate);
            }
            else {
                commit('SET_SUPPOSED_CONTROL_DATE', supposeDate);
            }
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        getDocument: (s) => s.document,
        getMinDate: (s) => s.minDate.format('YYYY-MM-DD'),
        getMaxDate: (s) => s.maxDate.format('YYYY-MM-DD'),
        getControlType: (s) => s.controlType,
        getControlDate: (s) => s.controlDate?.format('YYYY-MM-DD') ?? null,
        getSupposedControlDate: (s) => s.supposedControlDate?.format('DD.MM.YYYY'),
        getPeriodDeltaType: (s) => s.periodDeltaType,
        getPeriodDelta: (s) => s.periodDelta,
        getPeriodEndDate: (s) => s.periodEndDate?.format('YYYY-MM-DD') ?? null,
        getIsConsiderWeekend: (s) => s.isConsiderWeekends
    }
}

export default registerSection;