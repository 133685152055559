const createNewVersion = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
    },
    actions: {
        async open({ commit }) {
            
            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ copyAttachments: true });
        },
        async no({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ copyAttachments: false });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible
    }
}

export default createNewVersion;