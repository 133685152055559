const printFishka = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        onBlank: false,
        hideExecuters: false,
        language: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_ON_BLANK(state, payload) {
            state.onBlank = payload;
        },
        SET_HIDE_EXECUTERS(state, payload) {
            state.hideExecuters = payload;
        },
        SET_LANGUAGE(state, payload) {
            state.language = payload;
        },
    },
    actions: {
        async open({ commit }) {
            commit('SET_LANGUAGE', null);
            commit('SET_ON_BLANK', false);
            commit('SET_HIDE_EXECUTERS', false);

            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ onBlank: state.onBlank, hideExecuters: state.hideExecuters, language: state.language });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isOnBlank: (s) => s.onBlank,
        isHideExecuters: (s) => s.hideExecuters,
        getLanguage: (s) => s.language,
    }
}

export default printFishka;