const setAdjustment = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        decision: null,

        decisions: [
            { code: 2, value: "Согласовать" },
            { code: 4, value: "Вернуть_на_доработку" },
            { code: 3, value: "Отказать" }
        ],

        comment: null,
        attachment: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DECISION(state, payload) {
            state.decision = payload;
        },
        SET_COMMENT(state, payload) {
            state.comment = payload;
        },
        SET_ATTACHMENT(state, payload) {
            state.attachment = payload;
        }
    },
    actions: {
        async open({ commit }) {
            commit('SET_DECISION', null);
            commit('SET_COMMENT', null);
            commit('SET_ATTACHMENT', null);

            commit('SET_VISIBLE', true);
            
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        changeDecision({ commit }, v) {
            if (v === 2) {
                commit('SET_COMMENT', null);
                commit('SET_ATTACHMENT', null);
            }

            if (v === 3) {
                commit('SET_ATTACHMENT', null);
            }

            commit('SET_DECISION', v);
        },
        async ok({ commit, state }, redirect) {
            commit('SET_VISIBLE', false);

            let result =  {
                resultCode: state.decision,
                comment: state.comment,
                attachment: state.attachment,
                redirect
            };
            
            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getDecisions: (s) => s.decisions,
        getDecision: (s) => s.decision,
        getComment: (s) => s.comment,
        getAttachment: (s) => s.attachment,
    }
}

export default setAdjustment;