import list from './lists/event-list';
import calendar from './lists/event-calendar';

export default {
    namespaced: true,
    state: () => ({
        collection : "EventsList",
        statuses: [
            { collection: "EventsList", value: "ListAll" },
            { collection: "EventsCalendar", value: "CalendarToday" },
        ],
        searches: [
            { collection: "EventsList", value: null },
            { collection: "EventsCalendar", value: null },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses.find(x => x.collection === state.collection)?.value ?? null;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection)?.value ?? null;
        }
    },
    modules: {
        list,
        calendar
    }
}