import Vue from 'vue';
import sys from '@/services/system';

const sourceData = {
    "groups": [
        {
            'name': "default",
            "label": "",
            "order": 0
        },
        {
            'name': "MonthlyAmountWithoutMeteringDevices",
            "label": "Ежемесячная_сумма_платежей_при_отсутствии_приборов_учета_(с_НДС)",
            "order": 1        
        }
    ],
	"fields": {
		"IsTypicalForm": { "label": "Типовой", "componentName": "v-field-edit-bool", "value": true, "group": "default", "order": 1 },
		"ContractNumber": { "label": "Номер_договора", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 2 },
		"ContractDate": { "label": "Дата_договора", "componentName": "v-field-edit-date", "value": null, "group": "default", "order": 3 },
        "ContractBeginDate": { "label": "Дата_начала_действия_договора", "componentName": "v-field-edit-date", "value": null, "group": "default", "order": 4 },	
		"ContractEndDate": { "label": "Дата_окончания_действия_договора", "componentName": "v-field-edit-date", "value": null, "group": "default", "order": 5 },	

        "Provisioner": { "label": "Поставщик", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 6 },
		
		"ProvisionServicesDate": { "label": "Дата_фактического_предоставления_услуг", "componentName": "v-field-edit-date", "value": null, "group": "default", "order": 7 },
		"ObjectAddress": { "label": "Адрес_расположения_объекта", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 8 },
		"AllowedVolumeOfDrinkingWater": { "label": "Разрешенный_объем_питьевой_воды,_куб.м_/год", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 9 },		
		"AllowedVolumeOfTechnicalWater": { "label": "Разрешенный_объем_технической_воды,_куб.м_/год", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 10 },
		"DistributedWasteWater": { "label": "Производственные_сточные_воды,_куб.м_/год", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 11 },

		"ForServicesProvided": { "label": "за_предоставляемые_услуги,_тенге", "componentName": "v-field-edit-number", "value": null, "group": "MonthlyAmountWithoutMeteringDevices", "order": 12},
		"MonthlyAmountForWaterSupply": { "label": "за_водоснабжение,_тенге_куб.м_/мес", "componentName": "v-field-edit-number", "value": null, "group": "MonthlyAmountWithoutMeteringDevices", "order": 13 },
		"MonthlyAmountForWaterDisposal": { "label": "за_водоотведение,_тенге_куб.м_/мес", "componentName": "v-field-edit-number", "value": null, "group": "MonthlyAmountWithoutMeteringDevices", "order": 14 },
		
        "RateAccumulationMunicipalWaste": { "label": "Норма_накопления_ком.отходов,_куб.м_в_год_на_1_чел.", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 15 },

		"CostServicesPersonPerMonth": { "label": "Стоимость_услуг_за_1_чел._в_месяц,_тенге", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 16 },
        "UsableArea": { "label": "Полезная_площадь_кв_м.", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 17 },
		"NumberOfResidence": { "label": "Количество_проживающих_лиц,_чел.", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 18 },

		"NameDocumentForObject": { "label": "Наименование_правоустанавливающего_документа_на_объект", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 19 },
		"NumberDocumentForObject": { "label": "Номер_правоустанавливающего_документа_на_объект", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 20 },
		"DateDocumentForObject": { "label": "Дата_правоустанавливающего_документа_на_объект", "componentName": "v-field-edit-date", "value": null, "group": "default", "order": 21 },
		"TotalAreaOfObject": { "label": "Общая_площадь_объекта,_кв.м", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 22 },

        "DesignLoadGcalh": { "label": "Проектная_нагрузка_Гкал_час", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 23 },
        "HotWaterConsumptiontonsmonth": { "label": "Расход_горячей_воды_тонн_мес", "componentName": "v-field-edit-text", "value": null, "group": "default", "order": 24 }, 
        "WaterLeakageRatetonsmonth": { "label": "Норма_утечки_воды_тонн_мес", "componentName": "v-field-edit-number", "value": null, "group": "default", "order": 25 },
	},	
	"serviceCodes": {
        "01110001": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate",
                "ObjectAddress"
			]		
		},
		"01050008": {
			"fields": [ 				
				"ContractNumber", 
				"ContractDate",
                "ContractBeginDate",
                "ContractEndDate",                
                "ObjectAddress",
                "AllowedVolumeOfDrinkingWater",
                "AllowedVolumeOfTechnicalWater",
                "DistributedWasteWater",
			]		
		},
		"01070002": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate",
                "ObjectAddress"
			]
		},
		"01050009": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate",
			
				"ProvisionServicesDate",
				"ObjectAddress",
				"AllowedVolumeOfDrinkingWater",		
				"AllowedVolumeOfTechnicalWater",
				"DistributedWasteWater",
				"ForServicesProvided",
				"MonthlyAmountForWaterSupply",
				"MonthlyAmountForWaterDisposal"
			]
		},
		"01100009": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate" 
			]	
		},
		"01100010": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate" 
			]	
		},
		"01050012": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate",
			
				"ProvisionServicesDate",
				"ObjectAddress",
				"AllowedVolumeOfDrinkingWater",		
				"AllowedVolumeOfTechnicalWater",
				"DistributedWasteWater",
				"ForServicesProvided",
				"MonthlyAmountForWaterSupply",
				"MonthlyAmountForWaterDisposal"
			]
		},
		"01070001": {
			"fields": [ 
                "IsTypicalForm", 
                "ContractNumber", 
                "ContractDate", 
                "ObjectAddress",
                "CostServicesPersonPerMonth", 
                "NumberOfResidence" 
            ]
		},
		"01100007": {
			"fields": [ 
                "IsTypicalForm", 
                "ContractNumber", 
                "ContractDate", 
                "ContractEndDate" 
            ]	
		},
		"01100008": {
			"fields": [ 
                "IsTypicalForm", 
                "ContractNumber", 
                "ContractDate", 
                "ContractEndDate" 
            ]	
		},
		"01100004": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
                "Provisioner",
				
				"ContractEndDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]
		},
		"01100005": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
                "Provisioner",
				
				"ContractEndDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]	
		},
		"01100006": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
                "Provisioner",
				
				"ContractEndDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]			
		},
		"01100011": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]	
		},
		"01100012": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]			
		},
		"01100013": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]		
		},
        "01109004": {
			"fields": [ 				
				"ContractNumber", 
				"ContractDate", 
                "Provisioner",				
				"ContractEndDate",				
			]
		},
        "01120003": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate" 
			]	
		},
        "01120004": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate" 
			]	
		},
		"01120012": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]	
		},
		"01130001": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress", 
				"NameDocumentForObject", 
				"NumberDocumentForObject", 
				"DateDocumentForObject", 
				"TotalAreaOfObject"
			]	
		},   
		"01140001": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate", 
				
				"ObjectAddress",				
			]	
		},    
        "01160002": {
			"fields": [ 
				"IsTypicalForm", 
				"ContractNumber", 
				"ContractDate",
                "ObjectAddress"
			]		
		},        
        "10010001": {
            "fields": [ 
                "ContractNumber",
                "ContractDate",
                "ObjectAddress",
                "RateAccumulationMunicipalWaste",
                "NumberOfResidence"
			]	
        },  
        "10020001": {
            "fields": [ 
                "ContractNumber",
                "ContractDate",
                // "ContractBeginDate",
                // "ContractEndDate",
                "ObjectAddress",
                // "AllowedVolumeOfDrinkingWater",		
                // "AllowedVolumeOfTechnicalWater",
                // "DistributedWasteWater"
			]	
        },  
        "10030001": {
            "fields": [ 
                "ContractNumber",
                "ContractDate",                
                "ContractEndDate",
                "ObjectAddress",
                "UsableArea",
                "NumberOfResidence",                
                "DesignLoadGcalh",
                "HotWaterConsumptiontonsmonth", 
                "WaterLeakageRatetonsmonth"
			]	
        },        
	}
};

const cscContractInfo = {
    namespaced: true,
    state: {
        isPending: false,
        isVisible: false,

        resolve: null,
        reject: null,

        dataSource: null,
        key: ""
    },
    mutations: {
        SET_IS_PENDING(state, payload) {
            state.isPending = payload;
        },
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },  
        SET_DATASOURCE(state, payload) {
            state.dataSource = payload;            
        },
        SET_KEY(state, payload) {
          state.key = payload;  
        },
        UPDATE_DATASOURCE(state, payload) {     
            
            if (!this._vm.$_.has(state.dataSource, payload.property)) 
                Vue.set(state.dataSource, payload.property, payload.value)
            else
                this._vm.$_.set(state.dataSource, payload.property, payload.value)
        },
    },
    actions: {
        async open({ commit }, serviceCode) {

            if (!Object.prototype.hasOwnProperty.call(sourceData.serviceCodes, serviceCode))
            {
                this._vm.$notify.alert(`Для услуги с кодом - ${serviceCode} не определены свойства.`);
                return;
            }

            let dataSource = this._vm.$_.orderBy(
                this._vm.$_(sourceData.serviceCodes[serviceCode].fields.map(f => ({name: f, field: JSON.parse(JSON.stringify(sourceData.fields[f])) }) ))
                    .groupBy('field.group')
                    .map((value, key) => ({ group: sourceData.groups.find(g => g.name == key), values: this._vm.$_.orderBy(value, ['field.order'], ['asc']) }))
                    .value(), 
                ['group.order'], ['asc']
            );
            
            commit('SET_KEY', sys.generateUUID());
            commit('SET_DATASOURCE',dataSource);
            commit('SET_IS_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state, rootGetters }) {
            commit('SET_IS_VISIBLE', false);
            
            let result = {
                DocumentId: rootGetters['actionsource/getDataSource'].Data.Object.Document.id,                           
            };

            state.dataSource.forEach(e => {
                e.values.forEach(v => {
                    result[v.name] = v.field.value;
                })
            });

            state.resolve(result);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        updateDataSource({ commit }, data) {
            commit('UPDATE_DATASOURCE', data);
        },
    },
    getters: {
        isPending: (s) => s.isPending,
        isVisible: (s) => s.isVisible,
        
        isValid: (s) => s.dataSource.some(e => !e.values.some(v => !(typeof(v.field.value) === 'boolean' || !!v.field.value) )),
        dataSource: (s) => s.dataSource,
        skey: (s) => s.key,
    }
}

export default cscContractInfo;