import { httpAPI } from "@/api/httpAPI";

const orderCounter = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        counter: null,

        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_COUNTER(state, payload) {
            state.counter = payload;
        },
        UPDATE_COUNTER(state, payload)
        {
            this._vm.$_.set(state.counter, payload.property, payload.value)
        }
    },
    actions: {
        updateDataSource({ commit }, data) {
            commit('UPDATE_COUNTER', data);
        },
        async open({ commit }, data) {
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);
            
            let response = await httpAPI({
                url: data.id != null ? `api/actions/edit?type=Orders.Counter&id=${data.id}` : `api/actions/new?type=Orders.Counter`,
                method: 'GET'
            });
            
            commit('SET_LOADING', false);

            if (response) {
                commit('SET_COUNTER', data.id != null ? response.data.payload.object : response.data.payload.Data.Object);
            }
            else {
                commit('SET_COUNTER', null);
            }

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.counter);
        },

        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getCounter: (s) => s.counter
    }
}

export default orderCounter;