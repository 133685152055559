import { httpAPI } from "@/api/httpAPI";

const sortByCode = (decisions) => {
    decisions.sort((a, b) => {

        let aCode = a.Code.split('.').map(i => parseInt(i));
        let bCode = b.Code.split('.').map(i => parseInt(i));
        
        let compareResult = 0;
        
        for (let i = 0; i < Math.min(aCode.length, bCode.length); i++) {
            if (aCode[i] < bCode[i])
                compareResult = -1;
            else if (aCode[i] > bCode[i])
                compareResult = 1;

            if (compareResult)
                break;
        }

        if (!compareResult) {
            if (aCode.length < bCode.length)
                compareResult = -1
            else if (aCode.length > bCode.length)
                compareResult = 1
        }

        return compareResult;                
    });
}

const euolAddExecution = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        redirectCodes: [ '6', '7', '8', '9' ],
        notNeedOutNumber: [ '3.2', '3.3' ],
        sendRequestCodes: [ '10' ],
        noAnswerCodes: [ '3.2', '3.3', '6', '7', '8', '9', '10' ],        
        
        executeCard: null, //карточка исполнения
        decisions: [], //справочник решений по обращению
        attachments: [], //Вложения для перенаправления
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_ATTACHMENTS(state, payload) {
            state.attachments = payload;
        },
        SET_DECISIONS(state, payload) {
            state.decisions = payload;
        },
        SET_EXECUTE_CARD(state, payload) {
            state.executeCard = payload;
        },
        UPDATE_EXECUTE_CARD(state, payload) {
            this._vm.$_.set(state.executeCard, payload.property, payload.value)
        }
    },
    actions: {      
        async open({ state, commit, dispatch, getters, rootGetters }) {
            //#region initialize
            commit('SET_EXECUTE_CARD', null);
            commit('SET_DECISIONS', []);
            commit('SET_ATTACHMENTS', []);
            //#endregion

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);                

            let response = await httpAPI({
                method: 'GET',
                url: `/api/actions/geteuolexecutionparams?id=${rootGetters['actionsource/getDataSourceIdentifier']}`,
            });

            if (response) {
                commit('SET_EXECUTE_CARD', response.data.payload.Data.Object);
                let date = await dispatch('references/getTimestamp', null, { root: true });
                commit('UPDATE_EXECUTE_CARD', { property: 'SendDate', value: `/Date(${Date.parse(date)})/` });
            }
            
            let decisions = Array.from(await dispatch('references/getReference', { id: 1034 }, { root: true }));
            sortByCode(decisions);

            if (getters.getExecuteCard.StOutRegState === 0)
                decisions = decisions.filter(i => state.noAnswerCodes.includes(i.Code));

            commit('SET_DECISIONS', decisions);  
            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.executeCard);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async selectSendedUGO({ state, dispatch, commit }) {
            try
            {
                commit('SET_VISIBLE', false);

                let selectMemberParams =  { 
                    title: "Выбор_уполномоченного_гос_органа",
                    includeOuterEnterprises: true,
                    multiple: false,
                    selected: state.executeCard?.SendedUGOGuid ? [ state.executeCard.SendedUGOGuid ] : [],
                }

                let organization = await dispatch('dialogs/selectMembersNew/open', selectMemberParams, { root: true });

                commit('UPDATE_EXECUTE_CARD', { property: 'SendedUGOGuid', value: organization.enterprise });
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                commit('SET_VISIBLE', true);
            }
        },
        async selectSendRequest({ state, dispatch, commit }) {
            try
            {
                commit('SET_VISIBLE', false);

                let selectMemberParams =  { 
                    title: "Выбор_организации",
                    includeOuterEnterprises: true,
                    multiple: false,
                    selected: state.executeCard?.SendRequestGuid ? [state.executeCard.SendRequestGuid] : [],
                }

                let organization = await dispatch('dialogs/selectMembersNew/open', selectMemberParams, { root: true });

                commit('UPDATE_EXECUTE_CARD', { property: 'SendRequestGuid', value: organization.enterprise });
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                commit('SET_VISIBLE', true);
            }
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        resolve: (s) => s.resolve,
        reject: (s) => s.reject,

        getRedirectCodes: s =>  s.redirectCodes,
        getNotNeedOutNumber: s => s.notNeedOutNumber,
        getSendRequestCodes: s => s.sendRequestCodes,
        getNoAnswerCodes: s => s.noAnswerCodes,

        getAttachments: s => s.attachments,
        getExecuteCard: s => s.executeCard,
        getDecisions: s => s.decisions,
    }
}

export default euolAddExecution;