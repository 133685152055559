const set_next_period_date = {
    namespaced: true,
    state: {
        visible: false,

        resolve: null,
        reject: null,

        date: null,

        minDate: null,
        maxDate: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_DATE(state, payload) {
            state.date = payload ? this._vm.$moment(payload) : null;
        },
        SET_MIN_DATE(state, payload) {
            state.minDate = this._vm.$moment(payload).add(1, 'days');
        },
        SET_MAX_DATE(state, payload) {
            state.maxDate = this._vm.$moment(payload);
        },
    },
    actions: {
        async open({ state, commit }, { min, max }) {
            commit('SET_MIN_DATE', min);
            commit('SET_MAX_DATE', max);
            commit('SET_DATE', state.minDate);

            commit('SET_VISIBLE', true);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.date.format('YYYY-MM-DD'));
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getDate: (s) => s.date.format('YYYY-MM-DD'),
        getMinDate: (s) => s.minDate.format('YYYY-MM-DD'),
        getMaxDate: (s) => s.maxDate.format('YYYY-MM-DD')
    }
}

export default set_next_period_date;