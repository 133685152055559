import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import UsersHub from './plugins/users-hub'
import ComponentMapper from './plugins/component-mapper'
import Helpers from './plugins/helper-functions'
import moment from 'moment';
import i18n from './i18n'
import { EventBus } from '@/event-bus';
import notifier from '@/services/notifier';
import VueApexCharts from 'vue-apexcharts';
import _ from "lodash";
import VueTheMask from 'vue-the-mask'
import AsyncComputed from 'vue-async-computed';

document.title = process.env.VUE_APP_TITLE || 'СЭД «ИНТЕГРО»';

Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$_ = _;
//изменение визуального значения справочников по текущей локали
Vue.prototype.$refLocale = (refItem, i18nlocale) => {
    let uniLocale;
    switch(i18nlocale)
    {
        case 'ru':
            uniLocale = 'ru-RU';
            break;

        default:
            uniLocale = 'kk-KZ';
            break;
    }
    
    if (Object.prototype.hasOwnProperty.call(refItem, 'UniValue') && refItem.UniValue) {
        return refItem.UniValue[uniLocale] // если в объекте UniValue есть свойство с именем uniLocale и оно не falsy (undefined, null, empty, e.t.c)
            ? refItem.UniValue[uniLocale] // возвращаем его
            : refItem.UniValue['ru-RU'] // в противном случае проверяем свойство ru-RU и если оно не falsy (undefined, null, empty, e.t.c)
                ? refItem.UniValue['ru-RU'] // возвращаем его
                : refItem.Value; // в противном случае возвращаем свойство Value
    }

    return refItem.Value;
};

/*число видимых кнопок в пагинации на списках разделов*/
Vue.prototype.$listPaginationTotal = 10;

Vue.config.productionTip = false;

Vue.directive('router-back', {
    bind(el) {
        el.addEventListener('click', (e) => {
            e.preventDefault()
            router.go(-1)
        })
    }
});

Vue.use(AsyncComputed, {
    errorHandler (stack) {
        console.log('AsyncComputed error!')
        console.log('---')
        console.log(stack)
    }
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.component(`attachments-tab`, () => import('@/components/tabs/attachments.vue'));
Vue.component(`history-tab`, () => import('@/components/tabs/history.vue'));
Vue.component(`resolutions-tab`, () => import('@/components/tabs/resolutions.vue'));
Vue.component(`actitems-tab`, () => import('@/components/tabs/actitems.vue'));
Vue.component(`notifications-tab`, () => import('@/components/tabs/notifications.vue'));
Vue.component(`signs-tab`, () => import('@/components/tabs/signs.vue'));
Vue.component(`linkeddocs-tab`, () => import('@/components/tabs/linkeddocs.vue'));
Vue.component(`adjustments-tab`, () => import('@/components/tabs/adjustments.vue'));
Vue.component(`euol-versions-tab`, () => import('@/components/tabs/euolversions.vue'));
Vue.component(`routes-tab`, () => import('@/components/tabs/routes.vue'));
Vue.component(`iqala-comments-tab`, () => import('@/components/tabs/iqalacomments.vue'));
Vue.component(`document-comments-tab`, () => import('@/components/tabs/documentcomments.vue'));
Vue.component(`goverment-registration-tab`, () => import('@/components/tabs/goverment-registration.vue'));

Vue.component(`v-doc-type-chip`, () => import('@/components/DocTypeChip.vue'));
Vue.component(`v-doc-version-chip`, () => import('@/components/DocVersionChip.vue'));

Vue.component(`v-employee-chip`, () => import('@/components/contractor-chips/v-employee-chip.vue'));
Vue.component(`v-enterprise-chip`, () => import('@/components/contractor-chips/v-enterprise-chip.vue'));
Vue.component(`v-workplace-chip`, () => import('@/components/contractor-chips/v-workplace-chip.vue'));
Vue.component(`v-doc-chip`, () => import('@/components/contractor-chips/v-doc-chip.vue'));

Vue.component(`v-document-link`, () => import('@/components/DocumentLink.vue'));

Vue.component(`v-card-panel`, () => import('@/components/card/v-card-panel.vue'));
Vue.component(`v-field`, () => import('@/components/card/v-field.vue'));
Vue.component(`v-field-edit-bool`, () => import('@/components/card/v-field-edit-bool.vue'));
Vue.component(`v-field-edit-text`, () => import('@/components/card/v-field-edit-text.vue'));
Vue.component(`v-field-edit-date`, () => import('@/components/card/v-field-edit-date.vue'));
Vue.component(`v-field-edit-number`, () => import('@/components/card/v-field-edit-number.vue'));

Vue.component(`v-execution-tree`, () => import('@/components/card/v-execution-tree.vue'));

Vue.component(`v-file-item`, () => import('@/components/files/v-file-item.vue'));

Vue.component(`v-contact-item`, () => import('@/components/ems/v-contact-item.vue'));

Vue.component(`v-common-chip`, () => import('@/components/CommonDocumentChip.vue'));

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
    created() {
        //региструем плагин и передаем в него хранилище нашего инстанса Vue
        Vue.use(UsersHub, this.$store);
        Vue.use(notifier, i18n);
        Vue.use(ComponentMapper);
        Vue.use(Helpers);
        Vue.use(VueTheMask);
    }
}).$mount('#app')