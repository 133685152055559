import readytoexecute from './lists/ReadyToExecuteControl'
import executed from './lists/ExecutedControl'
import svetofor from './lists/svetofor'

export default {
    namespaced: true,
    state: () => ({
        collection : "Svetofor",
        statuses: [
            { collection: "Svetofor", value: "0" },
            { collection: "ReadyToExecuteControl", value: "-1" },
            { collection: "ExecutedControl", value: "-1" }
        ],
        searches: [
            { collection: "Svetofor", value: null },
            { collection: "ReadyToExecuteControl", value: null },
            { collection: "ExecutedControl", value: null }
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == payload.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getStatus (state) {
            return state.statuses.find(x => x.collection === state.collection).value;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        }
    },
    modules: {
        readytoexecute,
        executed,
        svetofor
    }
}