const cscComment = {
    namespaced: true,
    state: {
        isVisible: false,

        resolve: null,
        reject: null,

        comment: null,

    },
    mutations: {
        SET_IS_VISIBLE(state, payload) {
            state.isVisible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },

        SET_COMMENT(state, payload) {
            state.comment = payload;
        },

    },
    actions: {
        async open({ commit, }) {

            commit('SET_IS_VISIBLE', true);
            
            //Initialize
            commit('SET_COMMENT', null);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.resolve(state.comment);
        },
        async cancel({ commit, state }) {
            commit('SET_IS_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.isVisible,
        
        isValid: (s) => !!s.comment,
        comment: (s) => s.comment,
    }
}

export default cscComment;