export default {
    namespaced: true,
    state: () => ({
        tableSearch : "",
        tableOptions:
        {
            page: 1,
            itemsPerPage : 10,
            sortBy : ['recdate'],
            sortDesc : [true],
            groupBy : [],
            groupDesc : [],
            multiSort : false,
            mustSort : false
        },
        category: "OnRegistration",
        subCategories: [
            { category: "OnRegistration", value: "All" },
            { category: "Registered", value: "All" },
            { category: "Completed", value: "All" }
        ],
        extandedFilter:
        {            
            CreateDateFrom:
            {
                label: "Дата_создания_с",
                value: null
            },
            CreateDateTo:
            {
                label: "Дата_создания_по",
                value: null
            },
            RegDateFrom:
            {
                label: "Дата_регистрации_с",
                value: null
            },
            RegDateTo:
            {
                label: "Дата_регистрации_по",
                value: null
            },
            DeclarantType:
            {
                label: "Категория_лица",
                value: null
            },
            ExecDateFrom:
            {
                label: "Дата_исполнения_с",
                value: null
            },
            ExecDateTo:
            {
                label: "Дата_исполнения_по",
                value: null
            },
            ExecuterWorkplaceId:
            {
                label: "Исполнитель",
                value: null
            },
            RegistratorWorkplaceId:
            {
                label: "Регистратор",
                value: null
            },
            CameFrom:
            {
                label: "Поступило_из",
                value: null
            },
        }
    }),
    mutations: {
        SET_TABLE_OPTION (state, payload) {
            state.tableOptions = payload;
        },
        SET_TABLE_OPTION_PAGE (state, payload) {
            state.tableOptions.page = payload;
        },
        SET_TABLE_SEARCH (state, payload) {
            state.tableSearch = payload;
        },
        SET_TABLE_EXTANDED_FILTER (state, payload) {
            state.extandedFilter = payload;
        },
        SET_CATEGORY (state, payload) { 
            state.category = payload;
            switch(state.category)
            {
                case 'OnRegistration':
                    state.tableOptions.sortBy = ['recdate']            
                    state.tableOptions.sortDesc = [true]
                    break;
                case 'Registered':
                    state.tableOptions.sortBy = ['regdate']            
                    state.tableOptions.sortDesc = [true]
                    break;
                case 'Completed':
                    state.tableOptions.sortBy = ['execdate']            
                    state.tableOptions.sortDesc = [true]
                    break;

            }
        },
        PUSH_SUB_CATEGORY (state, payload) { 
            let subExists = state.subCategories.find(x => x.category == payload.category);

            if (subExists) {
                subExists.value = payload.value;
            } else {
                state.subCategories.push(payload);
            }
        }
    },
    actions: {
    },
    getters: {
        GetTableOptions (state) {
            return state.tableOptions;
        },
        GetTableSearch (state) {
            return state.tableSearch;
        },
        GetTableExtandedFilter (state) {
            return state.extandedFilter;
        },
        GetCategory: s => s.category,
        GetSubCategories: s => s.subCategories
    }
}