const beginAdjustment = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        parallel: false,
        adjusters: [],
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_PARALLEL(state, payload) {
            state.parallel = payload;
        },
        SET_ADJUSTERS(state, payload) {
            state.adjusters = payload;
        },
    },
    actions: {
        async open({ commit }) {

            commit('SET_PARALLEL', false);
            commit('SET_ADJUSTERS', []);

            commit('SET_VISIBLE', true);
            
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async selectAdjusters({ state, dispatch, commit, rootGetters }) {
            try
            {
                commit('SET_VISIBLE', false);

                let hasCommonService = rootGetters['auth/isCommonServiceEnabled'];
                let isOutgoingDoucment = rootGetters['actionsource/getDataSourceType'] === "OutgoingDocument";
                let isCommonDoucment = rootGetters['actionsource/isDataSourceCommon'];
                let isNPA = rootGetters['actionsource/getDataSourceType'] === "NPADocument";
                let selectMemberParams = null;
                if (isNPA){
                    selectMemberParams =  { 
                        title: "Выбор_согласующих",
                        NPAAdjasments: true,
                        includeInner: true,
                        includeOuter: true,
                        multiple: true,
                        selected: state.adjusters.map( x => x.workplaceId),
                        //EnterpriseAdjastmentName: rootGetters['actionsource/getDataSource']?.Data?.Object?.Document?.Card?.EnterpriseAcceptingActName["ru-RU"]
                    }
                }
                else {
                    selectMemberParams =  { 
                        title: "Выбор_согласующих",
                        includeInner: true,
                        includeOuter: isOutgoingDoucment && isCommonDoucment && hasCommonService,
                        includeExecutersGroups: true,
                        multiple: true,
                        selected: state.adjusters.map( x => x.workplaceId),
                    }
                }

                let adjusters = await dispatch('dialogs/selectMembersNew/open', selectMemberParams, { root: true });

                commit('SET_ADJUSTERS', adjusters);
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                commit('SET_VISIBLE', true);
            }
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ parallel: state.parallel, workPlaceIds: state.adjusters.map( x => x.workplaceId) });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isValid: (s) => s.adjusters.length > 0,
        isParallel: (s) => s.parallel,
        getAdjusters: (s) => s.adjusters
    }
}

export default beginAdjustment;