import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n'

const registerEuolStatementAs = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,

        mode: "",

        origin: null,
        currentParams: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_MODE(state, payload) {
            state.mode = payload;
        },
        SET_ORIGIN(state, payload) {
            state.origin = payload;
        },
        SET_CURRENT_PARAMS(state, payload) {
            state.currentParams = payload;
            //тут нужно поменять параметры уникального номера
            state.currentParams.NeedUniqueNumber = false;
            state.currentParams.PostUniqueNumber = null;
        },
        UPDATE_CURRENT_PARAMS(state, payload) {
            this._vm.$_.set(state.currentParams, payload.property, payload.value)
        }
    },
    actions: {
        async open({ commit, dispatch }, mode) {
            commit('SET_MODE', mode);
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            await dispatch('fetchParams');
            await dispatch('references/getReference', { id: 5200 }, { root: true });

            commit('SET_LOADING', false);
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ param: state.currentParams, originId: state.origin.id });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
        async fetchParams({ state, commit, rootGetters }) {

            let mode = state.mode === 'Repeat' ? 1 : 2;
            let originId = state.origin.id;

            let response = await httpAPI({
                url: `api/references/euolduplicateregisterparams?id=${rootGetters['actionsource/getDataSourceIdentifier']}&originId=${originId}&mode=${mode}`,
                method: 'GET'
            });

            if (response)
                commit('SET_CURRENT_PARAMS', response.data.payload?.Data.Object);
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getMode: (s) => s.mode,
        getOrigin: (s) => s.origin,
        getOriginStatement: function(s)
        {
            if (!s.origin)
                return "";

            let text = i18n.t('Обращение');

            if (s.origin.regnumber?.length > 0)
                text += ` № ${s.origin.regnumber}`;

            if (s.origin.regdate?.length > 0)
                text += ` ${i18n.t('от_время')} ${s.origin.regdate}`;
            
            if (s.origin.annotation?.length > 0)
                text += `\n${i18n.t('Краткое_содержание')}: ${s.origin.annotation}`;
            
            if (s.origin.executername?.length > 0)
                text += `\n${i18n.t('Исполнитель')}: ${s.origin.executername}`;

            if (s.origin.declarant?.length > 0)
                text += `\n${i18n.t('Заявитель')}: ${s.origin.declarant}`;

            return text;
        },
        getDuplicateValue: (s) => s.currentParams?.Duplicate ?? 1,
        getDuplicateReason: (s) => s.currentParams?.DuplicateReason,
        getPostUniqueNumber: (s) => s.currentParams?.PostUniqueNumber,
        getUniqueNumberParam: (s) => {
            if (s.currentParams?.NeedUniqueNumber === false)
                return 0;
            
            if (s.currentParams?.NeedUniqueNumber === true && s.currentParams?.PostUniqueNumber == null)
                return 1;

            if (s.currentParams?.NeedUniqueNumber === true && s.currentParams?.PostUniqueNumber != null)
                return 2;

            return 0;
        },
        isValid: (s) => 
        {
            if (s.currentParams?.Duplicate < 1)
                return false;

            if (s.mode === 'Repeat' && s.currentParams?.DuplicateReason == null)
                return false;

            if (s.currentParams?.PostUniqueNumber != null && s.currentParams.PostUniqueNumber.length < 13)
                return false;

            return true;
        }
    }
}

export default registerEuolStatementAs;