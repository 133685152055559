const KnownForms = 
{
    "0202010": 'attachments-tab',
    "0202060": 'history-tab',
    "0202030": 'resolutions-tab',
    "0202130": 'notifications-tab',
    "0202050": 'signs-tab',
    "0202070": 'linkeddocs-tab',
    "0202040": 'adjustments-tab',
    "0202100": 'euol-versions-tab',
    "0202120": 'actitems-tab',
    "0202090": 'routes-tab',
    "0202140": 'iqala-comments-tab',
    "0202150": 'document-comments-tab',
    "GovermentRegistration": 'goverment-registration-tab',
}

export default {
    install (Vue) {
        Vue.prototype.$mapComponent = function (formId) {
            if(!Object.keys(KnownForms).includes(formId))
                console.log(`missing component formID ${formId}`);

            if (typeof formId === 'string')
                return KnownForms[formId];
                
        }
    }
};